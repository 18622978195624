import TableComponent from "components/TableComponent";
import { transformDropdownOptions } from "containers/AddProject/utils";
import { Column } from "interface";
import { ChangeEvent, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';
import UsePeople from "HOC/UsePeople";
import { getUserDetail } from "utils/oktaAuth";
import { getUserName } from "utils/global";
import { Button, Dropdown, DropdownProps, Loader, Table } from "semantic-ui-react";
import { deleteProjectById, listAdmins, requestAppSettings, requestHomeList } from "store/list";
import { requestHomeDropdown } from "store/rext";
import { getAdminAppSettings, getAdminList, getAppState, getHomeDropdown, getHomeList } from "store/selectors";
import { transformDate } from "utils/global";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";

interface IFilter {
  paId: any;
  filter: string;
  status: string;
  projectType: string;
}

const columns: Column[] = [
  { name: 'Project No.', value: 'project_no', sort: false },
  { name: 'Project Name', value: 'project_name', sort: false },
  { name: 'Last Modified', value: 'last_modified', sort: false },
  { name: 'Status', value: 'status', sort: false },
  { name: 'Actions', value: 'actions', sort: false }
];

const projectTypesDropdown = [
  { key: "ALL", text: "All Projects", value: "ALL" },
  { key: "SPONSORED", text: "Sponsored", value: "SPONSORED" },
  { key: "COSPONSORED", text: "Co-Sponsored", value: "COSPONSORED" }
]

const Projects = () => {
  let [searchParams] = useSearchParams();
  let urlPaId = searchParams.get("PA_ID") || '';
  const [filterSelections, setFilterSelections] = useState<IFilter>({
    'paId': urlPaId || '18',
    'filter': 'LAST_100',
    'status': 'ALL',
    "projectType": "ALL"
  });
  const [paSelection, setPaSelection] = useState<string>('All Areas');
  const dispatch = useDispatch();
  const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);

  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const { hrid } = getUserDetail(authState!);
  const { user } = UsePeople(hrid)

  const { data: dropDownData } = useSelector(getHomeDropdown);
  const { data: homeList, fetching: homefetching } = useSelector(getHomeList);
  const { data: appSettingsData, fetching: appSettingsFetching } = useSelector(getAdminAppSettings);
  const { data: adminList } = useSelector(getAdminList);

  const renderRows = (item: any) => {
    const { id, paId } = item;
    return (
      <Table.Row key={id}>
        <Table.Cell><b>{item?.projectNumber}</b></Table.Cell>
        <Table.Cell><b>{item?.projectName}</b></Table.Cell>
        <Table.Cell>{transformDate(item?.lastModified)}</Table.Cell>
        <Table.Cell>{item?.status}</Table.Cell>
        <Table.Cell>
          {item?.isView ? (
            <Button size='mini' className="standard" onClick={() => viewProject(id, paId)}>View</Button>
          ) : (
            <>
              <Button size='mini' className="standard" onClick={() => updateProject(id, paId)}>Edit</Button>
              <Button size='mini' className="standard" onClick={() => deleteProject(id)}>Delete</Button>
            </>
          )}
        </Table.Cell>
      </Table.Row>
    )
  }

  const addProject = () => {
    navigate(`/add-project?PA_ID=${filterSelections.paId}`);
  }

  const updateProject = (id: string, paId: string) => {
    navigate(`/edit-project?PA_ID=${paId}&projectID=${id}`);
  }

  const viewProject = (id: string, paId: string) => {
    navigate(`/view-project?PA_ID=${paId}&projectID=${id}`);
  }

  const deleteProject = (id: string) => {
    dispatch(modalOperation.showModal(ModalsType.AlertModal, {
      onSave: () => {
        dispatch(deleteProjectById(id))
        dispatch(modalOperation.hideModal());
        setTimeout(() => {
          dispatch(requestHomeList(filterSelections));
        }, 1200);
      },
      onClose: () => {
        dispatch(modalOperation.hideModal());
      },
      title: "Confirmation",
      message: "Are you sure you want to delete this Project?",
      secondaryBtnTitle: "Cancel",
      primaryBtnTitle: "Yes",
    }));
  }

  const allOptions = useMemo(() => {
    return {
      filterOption: transformDropdownOptions(dropDownData?.dropdowns?.filter),
      initiativesOptions: transformDropdownOptions(dropDownData?.dropdowns?.initiativesDropdowns),
      statusOptions: transformDropdownOptions(dropDownData?.dropdowns?.status)
    }
  }, [dropDownData]);

  const handleFilter = (e: any, data: DropdownProps): void => {
    const { name, value } = data;
    setFilterSelections((prevState) => ({ ...prevState, [name]: value }));
  }

  const getPaName = () => {
    allOptions?.initiativesOptions.filter((item: any) => {
      if (item.key == filterSelections?.paId) {
        setPaSelection(item.text)
      }
    })
  }

  const getPaId = (paName: string) => {
    const filteredDropdown = allOptions?.initiativesOptions.filter((dropdown: any) => dropdown.text === paName);
    return filteredDropdown;
  }

  useEffect(() => {
    dispatch(requestHomeDropdown());
    dispatch(requestAppSettings());
    dispatch(listAdmins());
  }, [dispatch]);

  useEffect(() => {
    dispatch(requestHomeList(filterSelections));
    getPaName();
  }, [dispatch, filterSelections]);

  useEffect(() => {
    if (adminList?.length) {
      const isAdmin = adminList?.some(user => user.hrId == hrid);
      setUserIsAdmin(isAdmin);
    }
  }, [adminList]);

  const renderTitle = () => {
    return (
      <Table.Row className="table-title">
        <Table.HeaderCell>
          <h5>{paSelection} Projects</h5>
        </Table.HeaderCell>
        <Table.HeaderCell colSpan='3'></Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          {filterSelections?.paId !== '18' && (
            <Button className="flex-justify-end standard" size="mini" onClick={() => addProject()}>Add a New Project</Button>
          )}
        </Table.HeaderCell>
      </Table.Row>
    )
  }

  const handleNavigation = (link: string) => {
    if (urlPaId) {
      navigate(`../${link}`, { replace: true });
    } else {
      navigate(link);
    }
  }

  return (
    <div className="padding-l-14 padding-r-14 margin-t-6">
      <div className="flex flex-justify-between">
        <p className="text-bold text-md text-primary-color flex-justify-start">Welcome {getUserName(user)}</p>
        <div className="flex-justify-end">
          <ul className="horizontal-list">
            {userIsAdmin && (
              <li>
                <a onClick={() => handleNavigation('admin-options')} className="text-bold text-md text-primary-color text-underline text-hover-underline text-hover-primary cursor-pointer">Admin Options</a>
              </li>
            )}
            <li>
              <a href={appSettingsData[3]?.value} target="_blank" className="text-bold text-md text-primary-color text-underline text-hover-underline text-hover-primary cursor-pointer">{appSettingsData[2]?.value}</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="margin-t-8">
        <div className="flex flex-row">
          <span className="text-md flex-align-self-center">Practice Area:</span>

          <Dropdown
            className="margin-l-4"
            placeholder='Select PA'
            name="paId"
            selection
            options={allOptions.initiativesOptions}
            defaultValue={filterSelections.paId}
            search
            onChange={handleFilter}
          />

          <Dropdown className="margin-l-4"
            placeholder='Select Timeline'
            selection
            name="filter"
            options={allOptions.filterOption}
            defaultValue={filterSelections.filter}
            onChange={handleFilter}
          />

          <Dropdown className="margin-l-4"
            placeholder='Select Status'
            selection
            name="status"
            options={allOptions.statusOptions}
            defaultValue={filterSelections.status}
            onChange={handleFilter}
          />

          <Dropdown className="margin-l-4"
            placeholder='Type of projects'
            selection
            name="projectType"
            options={projectTypesDropdown}
            defaultValue={filterSelections.projectType}
            onChange={handleFilter}
          />
        </div>
      </div>

      <div className="margin-t-6">
        <TableComponent noDataMessage="There are no items." sortable={false} celled={false} coloredBg={true} tableTitle={renderTitle()}
          fetching={homefetching || false} columns={columns} rows={homeList || []}>
          {
            homeList?.map(renderRows)
          }
        </TableComponent>
        {homeList && homeList.length == 0 && !homefetching && (
          <>
            <Button className="flex-justify-end standard" size="mini" onClick={() => addProject()}>Add a New Project</Button>
          </>
        )}
      </div>

    </div>
  );
};

export default Projects;