import { AuthState, OktaAuth } from "@okta/okta-auth-js";

export const getOkatAuth = () => {
    return new OktaAuth({
        issuer: process.env.REACT_APP_OKTA_ISSUER,
        clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
        redirectUri: window.location.origin + '/login/callback',
        scopes: ['openid', 'profile', 'email'],
        pkce: false
    });
}


export function getUserDetail(authState: AuthState): {
    name: string;
    hrid: string;
  } {
    const { accessToken, idToken } = authState || {};
    if (accessToken?.claims && idToken?.claims) {
      const { HRID } = accessToken.claims;
      const { name } = idToken.claims
      return {
        name: name as string,
        hrid: HRID as string
      }
    }
    return {
      name: "",
      hrid: ""
    };
  }
