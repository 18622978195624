import Breadcrumb from "components/Breadcrumb";
import TableComponent from "components/TableComponent";
import { Column } from "interface";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Dropdown, Table } from "semantic-ui-react";
import { deleteInitiative, listInitiatives } from "store/list";
import { requestInitiativeDropdown } from "store/rext";
import { getInitiativeDropdown, getInitiavesList } from "store/selectors";
import { transformItemToDropdownOption } from "utils/global";

const columns: Column[] = [
    { name: 'Practice Area ID', value: 'pa_id', sort: false },
    { name: 'Practice Area', value: 'practice_area', sort: true },
    { name: 'Initiative ID', value: 'initiative_id', sort: false },
    { name: 'Initiative Text', value: 'initiative_text', sort: true },
    { name: '# Projects', value: 'projects', sort: true },
    { name: '', value: 'action', sort: false }
];


const ListInitiatives = () => {
    let [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let practiceAreaId: string = searchParams.get("PA_ID") || '';

    const { data: ListInitiatives, fetching: Initiativefetching } = useSelector(getInitiavesList);

    const { data: dropDownData } = useSelector(getInitiativeDropdown);

    const renderRows = (item: any) => {
        const { id, paId, paName, initiativeText, numberOfProjects } = item;
        return (
            <Table.Row key={id}>
                <Table.Cell>{paId}</Table.Cell>
                <Table.Cell>{paName}</Table.Cell>
                <Table.Cell>{id}</Table.Cell>
                <Table.Cell>{initiativeText}</Table.Cell>
                <Table.Cell>{numberOfProjects}</Table.Cell>
                <Table.Cell>
                    {
                        !numberOfProjects && <a onClick={() => deleteInitiativeItem(id)} className="text-underline text-hover-underline cursor-pointer">delete</a>
                    }
                </Table.Cell>
            </Table.Row>
        )
    }

    const addInitiative = () => {
        navigate('/admin-options/list-initiatives/add-initiative');
    }

    const deleteInitiativeItem = (id: number) => {
        dispatch(deleteInitiative(id))
    }

    const selectPA = (e: any, data: any) => {
        navigate(`/admin-options/list-initiatives?PA_ID=${data.value}`);
    }
    const Options = transformItemToDropdownOption(dropDownData?.dropdowns?.initiativesDropdowns || [])

    useEffect(() => {
        dispatch(listInitiatives(practiceAreaId!))
    }, [dispatch, practiceAreaId])

    useEffect(() => {
        dispatch(requestInitiativeDropdown())
    }, [dispatch])

    return (
        <div className="padding-l-14 padding-r-14 margin-t-8">

            <Breadcrumb />

            <div className="width-70">
                <div className="flex flex-justify-between margin-t-8">
                    <Dropdown
                        placeholder='filter by PA'
                        selection
                        options={Options}
                        value={practiceAreaId}
                        onChange={selectPA}
                    />

                    <Button className="flex-justify-end" size="mini" onClick={addInitiative}>Add a new Initiative</Button>
                </div>

                <div className="margin-t-6">
                    <TableComponent noDataMessage="There are no items." sortable={false} celled={true}
                        fetching={!!Initiativefetching} columns={columns} rows={ListInitiatives || []}>
                        {
                            ListInitiatives?.map(renderRows)
                        }
                    </TableComponent>
                </div>
            </div>
        </div>
    )
}

export default ListInitiatives;