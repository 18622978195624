import { Fragment } from "react"
import { Loader } from 'semantic-ui-react'
import './Loading.scss';

const Loading = ({ isProgressLoader }: any) => {

    return (
        <Fragment>
            {
                isProgressLoader ?
                    <div className="progress-bar">
                        <div className="progress-bar-value"></div>
                    </div>
                    :
                    <div className="padding-14">
                        <Loader active inline='centered' size='large'>Loading</Loader>
                    </div>
            }
        </Fragment >
    );
}

export default Loading;