import { FormDropdown, FormInput } from "components/FormInputs";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { Button, Grid, Popup, Table } from "semantic-ui-react";
import { formatUSD, transformDropdownOptions } from "../utils";
import { useDispatch } from "react-redux";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { transformDate } from "utils/global";
import { getRevenueCredit } from "store/list";
import { useSelector } from "react-redux";
import { getRevenueCreditNameState } from "store/selectors";
import Loading from "components/Loading";

const GeneralInformation = ({ register, errors, getValues, setValue, trigger, reset, control, watch, dropDownData, paId, isEditable, editData, isViewMode, financeProjectCode, dates, fetching, paApprovalCode, isAddProject, revenueMDP }: any) => {
    const dispatch = useDispatch();
    const [isRevenueMdp, setIsRevenueMdp] = useState<boolean>(true);
    const [coSponseredPaNameIndex, setCoSponseredPaNameIndex] = useState<number>(0);
    const [showFundingTable, setShowFundingTable] = useState<any>(false);

    const { data: revenueCreditData, fetching: fetchRevenueCredit } = useSelector(getRevenueCreditNameState);

    const { fields, append } = useFieldArray({
        name: "funding",
        control
    });

    const watchFields = useWatch({
        control,
        name: ['projectType', 'coSponsored', 'investmenttype', 'funding'],
    });

    const watchClientContributionField = useWatch({
        control,
        name: 'clientContribution',
    });

    const watchFundingFieldArray = watch("funding");

    const handlePaNameChange = (index: number, data: any) => {
        const { value } = data;
        setCoSponseredPaNameIndex((prevIndex) => index);
        setValue(`funding.${index}.paName`, value);
        if (value !== '21') {
            dispatch(getRevenueCredit(value));
        }
        if (value === '21') {
            setIsRevenueMdp(false)
            setValue(`funding.${index}.revenueCreditMdp`, '');
            setValue(`funding.${index}.billingOfficer`, '');
        } else {
            setIsRevenueMdp(true)
        }
    }

    const addFunding = () => {
        append({ type: 'Co-Sponsoring PA', paName: '', timeFunded: '', expenseFunded: '', billingOfficer: '', revenueCreditMdp: '', chargeCode: '', status: '1' });
    }

    const openStaffChooser = (section: string, index = 0) => {
        dispatch(modalOperation.showModal(ModalsType.StaffChooserModal, {
            onSelect: (data: any) => {
                if (section === 'general') {
                    setValue(`funding.${index}.billingOfficer`, data !== null ? data.preferredName : "");
                } else if (section === 'revenue-mdp') {
                    setValue(`funding.${index}.revenueCreditMdp`, data !== null ? data.preferredName : "");
                } else {
                    setValue(`paManagementContact`, data);
                }
                dispatch(modalOperation.hideModal())
            }
        }))
    }

    const allOptions = useMemo(() => {
        return {
            coSponsoredOption: transformDropdownOptions(dropDownData?.coSponsoredOptionDropdown),
            initiativeOptions: transformDropdownOptions(dropDownData?.initiativeDropdown),
            projectTypeOptions: transformDropdownOptions(dropDownData?.projectTypeDropdown),
            typeofInvestmentOptions: transformDropdownOptions(dropDownData?.typeofInvestmentDropdown),
            coSponsoredPAOptions: transformDropdownOptions(dropDownData?.coSponsoredPADropdown),
            PaStatusOptions: transformDropdownOptions(dropDownData?.paStatus),
        }
    }, [dropDownData]);

    const handleStaffChooser: any = (section: string, index: number) => {
        openStaffChooser(section, index);
    };

    const getBillingOfficerName = (index: number) => {
        let name = getValues(`funding.${index}.billingOfficer.preferredName`) || '';
        return name;
    };

    const fundingRows = (item: any, index: number) => {
        return (
            <>
                <Table.Row key={`item-${index}`}>
                    <Table.Cell>
                        <FormInput
                            customClass="hide-form-border"
                            name={`funding.${index}.type`}
                            register={register}
                            readOnly={true}
                            disabled={watchFields[1] === 'Y' || index == 0 ? false : true}
                        />
                    </Table.Cell>
                    <Table.Cell width={3}>
                        <FormDropdown
                            name={`funding.${index}.paName`}
                            options={allOptions?.coSponsoredPAOptions}
                            register={register}
                            getValues={getValues}
                            setValue={setValue}
                            trigger={trigger}
                            customClass={index === 0 ? 'disable-edit' : ''}
                            disabled={watchFields[1] === 'Y' || index == 0 ? false : true}
                            defaultValue={(isAddProject && index === 0) ? paId.toString() : item.paName}
                            handleChange={(e: any, data: any) => handlePaNameChange(index, data)}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <FormInput
                            name={`funding.${index}.timeFunded`}
                            register={register}
                            disabled={watchFields[1] === 'Y' || index == 0 ? false : true}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <FormInput
                            name={`funding.${index}.expenseFunded`}
                            register={register}
                            disabled={watchFields[1] === 'Y' || index == 0 ? false : true}
                        />
                    </Table.Cell>
                    <Table.Cell width={2}>
                        <div className="flex">
                            <div className="width-80 margin-r-2">
                                <FormInput
                                    name={`funding.${index}.billingOfficer`}
                                    // value={getBillingOfficerName(index)}
                                    register={register}
                                    disabled={watchFields[1] === 'Y' || index == 0 ? false : true}
                                />
                            </div>
                            {!isViewMode && (
                                <img className="cursor-pointer flex-align-self-center" src="../lookup.jpg" onClick={() => handleStaffChooser('general', index)} />
                            )}
                        </div>
                    </Table.Cell>
                    <Table.Cell width={2}>
                        <div className="flex">
                            <div className="width-80 margin-r-2">
                                <FormInput
                                    name={`funding.${index}.revenueCreditMdp`}
                                    register={register}
                                    readOnly={true}
                                />
                            </div>
                            {!isViewMode && (
                                <img className="cursor-pointer flex-align-self-center" src="../lookup.jpg" onClick={() => handleStaffChooser('revenue-mdp', index)} />
                            )}
                        </div>
                    </Table.Cell>
                    <Table.Cell>
                        {financeProjectCode == null ? (
                            <FormInput
                                name={`funding.${index}.chargeCode`}
                                register={register}
                                readOnly={true}
                                customClass="hide-form-border"
                                disabled={watchFields[1] === 'Y' || index == 0 ? false : true}
                            />
                        ) : (
                            <FormInput
                                name={`funding.${index}.chargeCode`}
                                register={register}
                            // disabled={getValues(`funding.${index}.chargeCode`) !== '' ? false : true}
                            />
                        )}
                    </Table.Cell>
                    <Table.Cell>
                        <FormDropdown
                            name={`funding.${index}.status`}
                            options={allOptions?.PaStatusOptions}
                            register={register}
                            getValues={getValues}
                            setValue={setValue}
                            trigger={trigger}
                            customClass={(index === 0 || paApprovalCode == null) ? 'disable-edit hide-form-border' : ''}
                            defaultValue={index === 0 ? '2' : (getValues(`funding.${index}.status`) || '1')}
                            disabled={watchFields[1] === 'Y' || index == 0 ? false : true}
                            clearable={false}
                        />
                    </Table.Cell>
                </Table.Row>
                {watchFundingFieldArray[index]?.status === '3' && (
                    <Table.Row key={`denied-${index}`}>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>Reason</Table.Cell>
                        <Table.Cell>
                            <FormInput
                                name={`funding.${index}.reasonForDenial`}
                                register={register}
                                disabled={watchFields[1] === 'Y' || index == 0 ? false : true}
                            />
                        </Table.Cell>
                    </Table.Row>
                )}
            </>
        )
    }

    const budgetTime = getValues('funding');

    useEffect(() => {
        if (watchFields[1] === 'N') {
            for (var key in budgetTime) {
                if (budgetTime.length > 1) {
                    if (Number(key) !== 0) {
                        setValue(`funding.${Number(key)}`, { type: 'Co-Sponsoring PA', paName: '', timeFunded: '', expenseFunded: '', billingOfficer: '', revenueCreditMdp: '', chargeCode: '', status: '' });
                    }
                }
            };
            setValue(`funding.${0}.timeFunded`, 100);
            setValue(`funding.${0}.expenseFunded`, 100);
        } else {
            if (budgetTime.length === 1) {
                append({ type: 'Co-Sponsoring PA', paName: '', timeFunded: '', expenseFunded: '', billingOfficer: '', revenueCreditMdp: '', status: '1' });
            }
        }
    }, [watchFields[1]]);

    const timeExpenseSum = (property: string) => {
        var summed: any = 0;
        if (budgetTime) {
            if (property === 'timeFunded') {
                for (var key in budgetTime) {
                    summed += (budgetTime[key].timeFunded !== undefined ? Number(budgetTime[key].timeFunded) : 0);
                };
                return summed;
            } else {
                for (var key in budgetTime) {
                    summed += (budgetTime[key].expenseFunded !== undefined ? Number(budgetTime[key].expenseFunded) : 0);
                };
                return summed;
            }
        }
    }

    const typeOfInvestmentToolTip = () => {
        return (
            <>
                <p>Internal research:</p>
                <ul>
                    <li>Deep research / Break-through IP building that will fuel our thought leadership publication pipeline (mainly functional)</li>
                </ul>
                <p>Internal offer/product development:</p>
                <ul>
                    <li>Go to market IP / products/offer development.</li>
                </ul>
                <p>IP co-development at client:</p>
                <ul>
                    <li>Projects where the main purpose is to develop new IP/reusable assets at a client (co-invested with client or fully funded). Projects where the main purpose is to convert a commercial opportunity or develop the client relationship are not eligible for Innovation Fund and should instead be channeled via Regional Investment Desks to leverage War Chest.</li>
                </ul>
                <p>Others:</p>
                <ul>
                    <li>SATT events preparation</li>
                    <li>Internal PA efforts: e.g., competitive benchmark, market sizing</li>
                </ul>
            </>
        )
    }

    const openFpaIpaModal = (type: string) => {
        dispatch(modalOperation.showModal(ModalsType.FpaIpaModal, {
            onSelect: (data: any) => {
                console.log('ipa---', data);

                const filteredLabels = data
                    .filter((item: any) => item.fpaIpa)
                    .map((item: any) => item.label);

                const commaSeparatedLabels = filteredLabels.join(', ');

                if (type === 'fpa') {
                    setValue('fpa', commaSeparatedLabels)
                } else {
                    setValue('ipa', commaSeparatedLabels)
                }
                dispatch(modalOperation.hideModal())
            }
        }))
    }

    useEffect(() => {
        if (watchFields[2] !== '3') {
            setValue('clientName', "");
            setValue('clientContribution', "");
        }
    }, [editData, isEditable, watchFields[2]])

    useEffect(() => {
        if (paId === 21) {
            setIsRevenueMdp(false)
        }
    }, [revenueMDP])

    useEffect(() => {
        if (!fetchRevenueCredit) {
            setValue(`funding.${coSponseredPaNameIndex}.revenueCreditMdp`, watchFundingFieldArray[coSponseredPaNameIndex]?.paName !== '21' ? revenueCreditData?.revenueCreditMdp : '');
            setValue(`funding.${coSponseredPaNameIndex}.billingOfficer`, watchFundingFieldArray[coSponseredPaNameIndex]?.paName !== '21' ? revenueCreditData?.billingOfficer : '');
        }
    }, [revenueCreditData, coSponseredPaNameIndex])

    useEffect(() => {
        if (watchClientContributionField) {
            const replaceComma = watchClientContributionField?.replace(/,/g, '');
            const numberValue = parseFloat(replaceComma);
            const formattedValue = replaceComma ? formatUSD(numberValue) : '';
            setValue('clientContribution', formattedValue);
        }
    }, [watchClientContributionField])

    useEffect(() => {
        setTimeout(() => {
            setShowFundingTable(true)
        }, 200);
    }, []);

    return (
        <div className="border-3 border-primary-color">
            <h5 className="text-background-primary text-white-color padding-1 margin-0">General Information</h5>
            <div className="padding-4 margin-b-4">

                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <FormInput
                                label='Project Name'
                                name='projectName'
                                errors={errors}
                                register={register}
                                validation={{ required: true }}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <Grid>
                                <Grid.Column width={!isViewMode ? 14 : 8}>
                                    <FormDropdown
                                        label='Is this a co-sponsored project?'
                                        name='coSponsored'
                                        options={allOptions.coSponsoredOption || []}
                                        register={register}
                                        errors={errors}
                                        validation={{ required: true }}
                                        getValues={getValues}
                                        setValue={setValue}
                                        trigger={trigger}
                                    />
                                </Grid.Column>
                                <Grid.Column width={2} className="flex-align-self-center">
                                    <Popup size='mini' content='Co-sponsored projects require the approval of all identified practice areas.  By labeling this project co-sponsored and clicking the "Submit to PAs" button, 
                            an email alert requesting approval of the co-sponsorship will automatically be sent to the GM of each identified co-PA.' trigger={<img className="margin-t-7 cursor-pointer" src="../info.jpg" />} />
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <FormDropdown
                                label='Initiative'
                                name='initiative'
                                options={allOptions.initiativeOptions || []}
                                errors={errors}
                                register={register}
                                validation={{ required: paId === 21 }}
                                getValues={getValues}
                                setValue={setValue}
                                trigger={trigger}
                                customClass={paId !== 21 ? 'disable-edit grey-color' : ''}
                            />
                        </Grid.Column>

                        {/* <Grid.Column>
                            <Grid>
                                <Grid.Column width={!isViewMode ? 14 : 8}>
                                    <FormInput
                                        label="FPA(s) / Topic(s)"
                                        name="fpa"
                                        register={register}
                                        errors={errors}
                                        validation={{ required: false }}
                                        readOnly
                                    />
                                </Grid.Column>
                                <Grid.Column width={1} className="position-relative">
                                    {!isViewMode && (
                                        <img className="cursor-pointer align-search-icon" src="../lookup.jpg" onClick={() => openFpaIpaModal('fpa')} />
                                    )}
                                </Grid.Column>
                            </Grid>
                        </Grid.Column> */}
                    </Grid.Row>
                </Grid>

                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <FormDropdown
                                label='Project Type'
                                name='projectType'
                                options={allOptions.projectTypeOptions || []}
                                errors={errors}
                                register={register}
                                validation={{ required: true }}
                                getValues={getValues}
                                setValue={setValue}
                                trigger={trigger}
                                customClass={paId !== 21 ? 'disable-edit grey-color' : ''}
                            />
                        </Grid.Column>

                        {/* <Grid.Column>
                            <Grid>
                                <Grid.Column width={!isViewMode ? 14 : 8}>
                                    <FormInput
                                        label="IPA(s) / Sector(s)"
                                        name="ipa"
                                        register={register}
                                        errors={errors}
                                        validation={{ required: false }}
                                        readOnly
                                    />
                                </Grid.Column>
                                <Grid.Column width={1} className="position-relative">
                                    {!isViewMode && (
                                        <img className="cursor-pointer align-search-icon" src="../lookup.jpg" onClick={() => openFpaIpaModal('ipa')} />
                                    )}
                                </Grid.Column>
                            </Grid>
                        </Grid.Column> */}
                    </Grid.Row>
                </Grid>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column width={6}>
                            <FormDropdown
                                label='Type Of Investment'
                                name='investmenttype'
                                options={allOptions.typeofInvestmentOptions}
                                errors={errors}
                                register={register}
                                // validation={{ required: watchFields[0] !== '1' ? false : true }}
                                validation={{ required: true }}
                                getValues={getValues}
                                setValue={setValue}
                                trigger={trigger}
                            // disabled={watchFields[0] !== '1' ? true : false}
                            />
                        </Grid.Column>
                        <Grid.Column width={2} className="flex-align-self-center">
                            <Popup size='mini' content={typeOfInvestmentToolTip} trigger={<img className="margin-t-7 cursor-pointer" src="../info.jpg" />} />
                        </Grid.Column>
                    </Grid.Row>
                    {watchFields[2] === '3' && (
                        <Grid.Row columns={2} style={{ paddingTop: 0 }}>
                            <Grid.Column width={6}>
                                <label style={{ fontSize: '12px' }}>
                                    <strong>
                                        Note: IP co-development at client projects will be reviewed by the Global Investments team before the code is opened, which may take 24-48 hours.
                                    </strong>
                                </label>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>

                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column width={6}>
                            <FormInput
                                label="Client Name"
                                name="clientName"
                                register={register}
                                errors={errors}
                                validation={{ required: watchFields[2] !== '3' ? false : true }}
                                customClass={watchFields[2] !== '3' ? 'disable-edit grey-color' : ''}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column width={6}>
                            <FormInput
                                label="Client Contribution ($ USD)"
                                name="clientContribution"
                                register={register}
                                errors={errors}
                                validation={{ required: watchFields[2] !== '3' ? false : true }}
                                customClass={watchFields[2] !== '3' ? 'disable-edit grey-color' : ''}
                            />
                        </Grid.Column>
                        <Grid.Column width={2} className="flex-align-self-center">
                            <Popup size='mini' content="Total amount to be paid by client." trigger={<img className="margin-t-7 cursor-pointer" src="../info.jpg" />} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                    <Table size="small">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell ></Table.HeaderCell>
                                <Table.HeaderCell>PA Name</Table.HeaderCell>
                                <Table.HeaderCell>% Time Funded</Table.HeaderCell>
                                <Table.HeaderCell>% Expense Funded</Table.HeaderCell>
                                <Table.HeaderCell>
                                    <span className="float-left">Billing Officer</span>
                                    <Popup size='mini' content='Billing Officer is the PAL of the identified PA. For GTB projects, please enter the PAL of the lead PA.' trigger={<img className="margin-l-2 cursor-pointer" src="../info.jpg" />} />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <span className="float-left">Revenue Credit MDP</span>
                                    <Popup size='mini' content='PAL or other MDP that will allocate Revenue Credits to other MDPs' trigger={<img className="margin-l-2 cursor-pointer" src="../info.jpg" />} />
                                </Table.HeaderCell>
                                <Table.HeaderCell>Charge Code</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                showFundingTable ? fields?.map(fundingRows) : <Loading />
                            }
                            <Table.Row>
                                <Table.Cell width={3}>
                                    {!isViewMode && (
                                        <Button type="button" size="mini" onClick={addFunding} disabled={watchFields[1] === 'Y' ? false : true}>Add an Additional PA</Button>
                                    )}
                                </Table.Cell>
                                <Table.Cell>Total</Table.Cell>
                                <Table.Cell><span className={`${timeExpenseSum('timeFunded') !== 100 ? 'text-danger-color' : ''}`}>{timeExpenseSum('timeFunded')}</span></Table.Cell>
                                <Table.Cell><span className={`${timeExpenseSum('expenseFunded') !== 100 ? 'text-danger-color' : ''}`}>{timeExpenseSum('expenseFunded')}</span></Table.Cell>
                                <Table.Cell colSpan={3}>
                                    {(timeExpenseSum('timeFunded') !== 100 || timeExpenseSum('expenseFunded') !== 100) && <p className="text-form-error">Error: Total should not be greater than or less than 100%</p>}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>

                {!fetching && (
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column width={6}>
                                <div className="flex">
                                    <FormInput
                                        label='PA Management Contact'
                                        name='paManagementContact'
                                        value={getValues(`paManagementContact`)?.preferredName ? getValues(`paManagementContact`)?.preferredName : ''}
                                        errors={errors}
                                        register={register}
                                        validation={{ required: true }}
                                        readOnly={true}
                                    />
                                    {!isViewMode && (
                                        <img className="cursor-pointer flex-align-self-center margin-l-2 margin-t-2" src="../lookup.jpg" onClick={() => handleStaffChooser('paContact', null)} />
                                    )}
                                    <Popup size='mini' content="The author of the project request form, or the best contact for questions regarding the form and specifics around the project's logistics." trigger={<img className="cursor-pointer flex-align-self-center margin-l-2 margin-t-2" src="../info.jpg" />} />
                                </div>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <div className="flex flex-justify-between margin-t-8">
                                    <span className="">Approval Date</span>
                                    <span className="">{transformDate(dates?.generalInformation?.approvalDate)}</span>
                                </div>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2}>
                            <Grid.Column width={6}>
                                <div className="flex">
                                    <span className="margin-r-8">Proposal Date</span>
                                    <span className="margin-l-8">{transformDate(dates?.generalInformation?.proposalDate)}</span>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <div className="flex flex-justify-between">
                                    <span>Open Since</span>
                                    <span>{transformDate(dates?.generalInformation?.openSinceDate)}</span>
                                </div>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={4}>
                                <div className="flex">
                                    <span className="margin-r-8">Request Status</span>
                                    <span className="margin-l-8">{dates.generalInformation?.requestStatus || 'Draft'}</span>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        </div>
    )
}

export default GeneralInformation;