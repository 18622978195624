import { FormDatePicker, FormInput, FormTextArea } from "components/FormInputs";
import { useEffect } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import { Button, Grid, Popup, Table } from "semantic-ui-react";
import DatePicker from "react-datepicker";

const ProjectDescription = ({ register, errors, getValues, setValue, trigger, reset, control, isEditable, editData, isViewMode }: any) => {

    const { fields, append } = useFieldArray({
        name: "projectDeliverables",
        control
    });

    const deliverableRows = (item: any, index: number) => {
        return (
            <Table.Row key={index}>
                <Table.Cell>
                    <FormInput
                        name={`projectDeliverables.${index}.deliverable`}
                        register={register}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Controller
                        control={control}
                        name={`projectDeliverables.${index}.timelinesDate`}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker selected={value} onChange={onChange} isClearable />
                        )}
                    />
                </Table.Cell>
            </Table.Row>
        )
    }

    const addDeliverable = () => {
        append({ deliverable: '', timelinesDate: '' });
    }

    return (
        <div className="border-3 border-primary-color">
            <h5 className="text-background-primary text-white-color padding-1 margin-0">Project Description + Deliverables</h5>
            <div className="padding-4">
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <FormTextArea
                                label='Project Description / Rationale (one sentence minimum)'
                                name='projectDescription'
                                register={register}
                                errors={errors}
                                validation={{ required: true }}
                                rows={3}
                                readOnly={isViewMode}
                            />
                            <FormTextArea
                                label='Key Program Elements (how the investment will be leveraged)'
                                name='keyProgramElements'
                                register={register}
                                errors={errors}
                                validation={{ required: true }}
                                rows={3}
                                readOnly={isViewMode}
                            />
                            <FormTextArea
                                label='Competitor Context'
                                name='competitorContext'
                                register={register}
                                rows={3}
                                readOnly={isViewMode}
                            />
                            <FormTextArea
                                label='Marketing Plan'
                                name='marketingPlan'
                                register={register}
                                rows={3}
                                readOnly={isViewMode}
                            />
                            <FormTextArea
                                label='Expected Outcome'
                                name='expectedOutcome'
                                register={register}
                                errors={errors}
                                validation={{ required: true }}
                                rows={3}
                                readOnly={isViewMode}
                            />
                            <FormTextArea
                                label='What success will look like (in 1-2 years)'
                                name='futureGrowth'
                                register={register}
                                rows={3}
                                readOnly={isViewMode}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <div className="width-70">
                                <div className="width-80 float-left">
                                    <FormDatePicker
                                        label='Anticipated Start Date'
                                        name='anticipatedStartDate'
                                        register={register}
                                        getValues={getValues}
                                        setValue={setValue}
                                        errors={errors}
                                        validation={{ required: true }}
                                        trigger={trigger}
                                    />
                                </div>
                                <Popup size='mini' content='Please indicate only the time span during which staff will potentially be needed. 
                                    Please avoid including any inactive time periods.' trigger={<img className="cursor-pointer margin-t-10 margin-l-4" src="../info.jpg" />} />
                            </div>

                            <div className="width-70 margin-t-5 margin-b-5">
                                <div className="width-80 float-left">
                                    <FormDatePicker
                                        label='Anticipated Completion Date'
                                        name='anticipatedCompletiondate'
                                        register={register}
                                        getValues={getValues}
                                        setValue={setValue}
                                        errors={errors}
                                        validation={{ required: true }}
                                        trigger={trigger}
                                    />
                                </div>
                                <Popup size='mini' content='Please indicate only the time span during which staff will potentially be needed. 
                                    Please avoid including any inactive time periods.' trigger={<img className="cursor-pointer margin-t-10 margin-l-4" src="../info.jpg" />} />
                            </div>

                            <div className="width-60">
                                <FormInput
                                    label='Project Length (weeks)'
                                    name='projectLength'
                                    errors={errors}
                                    register={register}
                                    validation={{ required: true }}
                                />
                                <FormDatePicker
                                    label='Actual Completion Date'
                                    name='actualCompletiondate'
                                    register={register}
                                    getValues={getValues}
                                    setValue={setValue}
                                    trigger={trigger}
                                />
                            </div>

                            <div className="text-background-secondary padding-2 margin-t-6">
                                <p className="margin-0">Proposed Deliverables</p>
                            </div>

                            <Table size="small">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Deliverable</Table.HeaderCell>
                                        <Table.HeaderCell>Timeline</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        fields?.map(deliverableRows)
                                    }
                                </Table.Body>
                            </Table>
                            {!isViewMode && (
                                <Button type="button" size="mini" onClick={addDeliverable}>Add an additional Deliverable</Button>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    )
}

export default ProjectDescription;