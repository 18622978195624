import { Fragment, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import Footer from "components/Footer";
import Header from 'components/Header';
import Loading from 'components/Loading';

export const SecureRoute = () => {
    const { oktaAuth, authState } = useOktaAuth();

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect();
        }
    }, [oktaAuth, authState]);

    if (!authState || !authState?.isAuthenticated) {
        return (<Loading />);
    }

    return (
        <Fragment>
            <Header />
            <div className='page-content'>
                <Outlet />
            </div>
            <Footer />
        </Fragment>
    );
}
