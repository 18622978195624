import { Modal } from "semantic-ui-react";


export const ModalHeader = ({ title, toggle }: any) => {
  return (
    <Modal.Header className="text-align-center">{title}
    </Modal.Header>
  );
};

