export const server = {
    listInitiatives: "/admin/initiatives/:practiceAreaId",
    createInitiatives: "/admin/initiatives",
    listAdmins: "/admin/list-idb-admin",
    listInterns: "/admin/list-idb-interns",
    initiativesDropdown: "/admin/initiatives/dropdowns-with-count",
    deleteInitiative: "/admin/initiatives/:id",
    people: "/domain/v2/people",
    addProjectDropdown: "/project/dropdowns/:PA_ID",
    homeDropdown: "/home/dropdowns",
    homeList: "/home/project-list",
    listAdminPa: "/admin/pa-list",
    listAdminPaDetails: "/admin/adgroup-user/:paId",
    listAdminPaUpdate: "/admin/pa-list/:paId",
    project: "/project",
    updateProject: "/project/:id",
    editProject: "/project/:ProjectNumber",
    projectHistory: "/project/history/:projectId",
    projectSubmitToPa: "/project/submitToPa",
    emailCode: "/project/emailcode/:id",
    appSettings: "/admin/settings",
    saveChanges: "/project/savechanges",
    projectSubmitToFinance: "/project/submitToFinance",
    projectFinanceStatus: "/project/statusupdate/:path",
    adminChargeCode: "/project/search/:chargeCode",
    projectStatusDropdown: "/project/status-dropdowns",
    projectStatusSave: "/project/projectdata",
    revenueCredit: "/project/revenueCredit/:id",
}