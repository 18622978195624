import { Link, useLocation } from "react-router-dom";
import "./Breadcrumb.scss";

const menuItems = [
    { id: 1, name: 'List PA AD Groups and Approvers', link: 'pa-list' },
    { id: 2, name: 'List IDB Admins', link: 'list-admins' },
    { id: 3, name: 'Super Admin List', link: 'list-interns' },
    { id: 4, name: 'List Initiatives', link: 'list-initiatives' },
    { id: 5, name: 'Add/Update Charge Code', link: 'charge-code' },
    { id: 6, name: 'View/Update App Settings', link: 'app-settings' },
    { id: 7, name: 'Admin Options', link: 'admin-options' },
    { id: 8, name: 'Add Initiative', link: 'add-initiative' },
    { id: 9, name: ' View/Update App Settings', link: 'app-settings' },
    { id: 10, name: 'PA Details', link: 'pa-details' }

]

const Breadcrumb = () => {

    const { pathname } = useLocation();
    const pathnames = pathname.split("/").filter(Boolean);

    const createCrumb = (link: string, index: number) => {
        const menu = menuItems.find(el => el.link === link);
        const navigationLink = '/' + pathnames.slice(0, index+1).join('/');
        return (
            <li key={index} className="margin-l-4">
                {
                    index !== pathnames.length - 1 ?
                        <Link to={navigationLink} className="text-underline text-hover-underline">{menu?.name}</Link> :
                        menu?.name
                }
            </li>
        )
    }

    return (
        <ul className="breadcrumb">
            <li>PA Innovation</li>
            {
                pathnames.map(createCrumb)
            }
        </ul>
    );
}

export default Breadcrumb;