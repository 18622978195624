import { FormInput } from "components/FormInputs";
import TableComponent from "components/TableComponent/TableComponent";
import SingleUser from "containers/PAList/SingleUser";
import { Column } from "interface";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button, Divider, Form, Modal, Table } from "semantic-ui-react";
import { getAllBCGUsersByName } from "store/list";
import { getSearchBCGUsers } from "store/selectors";

interface Props {
    hideModal: () => void;
    onSelect: (staff: any) => void;
    type: string;
}

const columns: Column[] = [
    { name: 'Name', value: 'name', sort: false },
    { name: 'Office', value: 'office', sort: false },
    { name: 'Position', value: 'position', sort: false }
];

const StaffChooser = (props: Props) => {
    const dispatch = useDispatch();

    const { data: searchUserState, fetching } = useSelector(getSearchBCGUsers);
    
    const {
        hideModal
    } = props;
    const { register, handleSubmit, reset } = useForm();
    const [isHrId, setIsHrId] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [userListData, setUserListData] = useState<any>();

    const clearValue = () => {
        reset({
            search: ''
        });
        props.onSelect(null);
        props.hideModal()
    }

    const findUsers = (name: string) => {
        setSearchTerm(name)
        if (props.type == undefined || props.type !== 'hrid') {
            dispatch(getAllBCGUsersByName(name));
        }
    }

    const onSubmit = handleSubmit(data => {
        if (data.search) {
            findUsers(data.search);
        }
    });

    const onStaffSelect = (staff: any) => {
        props.onSelect(staff);
    }

    const renderRows = (item: any) => {
        const { id } = item;
        return (
            <Table.Row key={id}>
                <Table.Cell><a className="cursor-pointer text-underline text-hover-underline" onClick={() => onStaffSelect(item)}>{item?.preferredName}</a></Table.Cell>
                <Table.Cell>{item?.hostOfficeRegion}</Table.Cell>
                <Table.Cell>{item?.businessTitle}</Table.Cell>
            </Table.Row>
        )
    }

    useEffect(() => {
        if (searchUserState.length) {
            setUserListData(searchUserState)
        }
    }, [searchUserState]);

    useEffect(() => {
        setIsHrId(false);

        return () => {
            setUserListData([]);
            setSearchTerm('')
        }
    }, []);

    useEffect(() => {
        if (props.type !== undefined && props.type === 'hrid' && searchTerm !== '') {
            setIsHrId(true)
        }
    }, [searchTerm]);

    return (
        <Modal
            size="tiny"
            dimmer
            open
            onClose={hideModal}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Header>
                <div className="flex flex-justify-between">
                    <div className="flex-justify-start flex-align-self-center">
                        <h5 className="margin-0">Staff Chooser</h5>
                    </div>
                    <div className="flex-justify-end">
                        <Button basic className="cursor-pointer" size='mini' onClick={hideModal}>close &#10006;</Button>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Content>
                <p className="margin-0 text-bold text-md">{`${props.type === 'hrid' ? 'Enter User HR ID' : 'Enter any part of the Staff name'}:`}</p>

                <Form className="margin-b-6" size="small" onSubmit={onSubmit}>
                    <div className="width-70">
                        <div className="margin-t-4">
                            <FormInput
                                name='search'
                                register={register}
                            />
                        </div>

                        <div className="margin-t-6">
                            <Button size='mini' type="button" onClick={props.hideModal}>
                                Cancel Find
                            </Button>

                            <Button size='mini' type="button" onClick={clearValue}>
                                Clear Value
                            </Button>

                            <Button size='mini' loading={fetching} primary type="submit">
                                Search
                            </Button>
                        </div>
                    </div>
                </Form>

                <Divider />

                {
                    !isHrId && userListData?.length && searchTerm !== '' &&
                    <div className="margin-t-2">
                        <p className="text-bold text-md">Found {userListData?.length} results, click to select:</p>
                        <TableComponent noDataMessage="There are no items." sortable={false} celled={false}
                            fetching={fetching || false} columns={columns} rows={userListData}>
                            {
                                userListData?.map(renderRows)
                            }
                        </TableComponent>
                    </div>
                }
                {isHrId && searchTerm !== '' && (<SingleUser searchTerm={searchTerm} columns={columns} onStaffSelect={onStaffSelect} />)}

            </Modal.Content>

        </Modal>
    )
}

export default StaffChooser;