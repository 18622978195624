import { createList } from "baseProvider/list";
import { getBaseUrl, getBaseUrlAPIKEY, getToken } from "store/selectors";

export const initiativeList = createList({
	getBaseUrl: getBaseUrl(false),
	getXAPIToken: getBaseUrlAPIKEY(false),
	getToken,
	identity: "initiativeList",
	primaryKey: "id",
	itemsKeyName: "list",
	deleteMessage: "Initiative removed",
	addMessage: "Initiative created successfully"
})

export const adminList = createList({
	getBaseUrl: getBaseUrl(false),
	getXAPIToken: getBaseUrlAPIKEY(false),
	getToken,
	identity: "adminList",
	primaryKey: "hrId",
	itemsKeyName: "list"
})

export const internsList = createList({
	getBaseUrl: getBaseUrl(false),
	getXAPIToken: getBaseUrlAPIKEY(false),
	getToken,
	identity: "internsList",
	primaryKey: "hrId",
	itemsKeyName: "list"
})

export const homeList = createList({
	getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken,
	identity: "homeList",
	primaryKey: "id",
	itemsKeyName: "list",
	addMessage: "Home List created successfully",
	deleteMessage: "List item removed",
})

export const adminPaList = createList({
	getBaseUrl: getBaseUrl(false),
	getXAPIToken: getBaseUrlAPIKEY(false),
	getToken,
	identity: "adminPaList",
	primaryKey: "paId",
	itemsKeyName: "list",
	editMessage: "PA list updated successfully"
})

export const adminPaDetails = createList({
	getBaseUrl: getBaseUrl(false),
	getXAPIToken: getBaseUrlAPIKEY(false),
	getToken,
	identity: "adminPaDetails",
	primaryKey: "hrId",
	itemsKeyName: "list",
	addMessage: "Details fetched successfully"
})

export const projectList = createList({
	getBaseUrl: getBaseUrl(false),
	getXAPIToken: getBaseUrlAPIKEY(false),
	getToken,
	identity: "projectList",
	primaryKey: "id",
	itemsKeyName: "list",
	addMessage: "Project saved successfully",
	editMessage: "Project updated successfully",
})

export const projectHistoryList = createList({
	getBaseUrl: getBaseUrl(false),
	getXAPIToken: getBaseUrlAPIKEY(false),
	getToken,
	identity: "projectHistoryList",
	primaryKey: "user",
	itemsKeyName: "list"
})

export const adminAppSettings = createList({
	getBaseUrl: getBaseUrl(false),
	getXAPIToken: getBaseUrlAPIKEY(false),
	getToken,
	identity: "adminAppSettings",
	primaryKey: "id",
	itemsKeyName: "list",
	editMessage: "App Settings Updated successfully"
})