import { Table } from 'semantic-ui-react';
import TableLoading from "components/Loading/TableLoading";
import { Column } from 'interface';
import SortableHeader from './SortableHeader';
import './TableComponent.scss';

interface Props {
  columns: Column[];
  rows: any;
  noDataMessage: string;
  children?: any
  fetching: boolean;
  sortable: boolean;
  tableTitle?: any;
  celled: boolean;
  coloredBg?: boolean;
  width?: number;
}


function TableComponent({ columns, fetching, rows, celled, sortable, noDataMessage, tableTitle, coloredBg, width, children }: Props) {

  const renderHeader = (columns: Column[]) => {
    return (
      <Table.Header>
        {
          tableTitle
        }

        <Table.Row className='header-col'>
          {
            columns.map(col => (
              <Table.HeaderCell key={col.value}>{col?.name}</Table.HeaderCell>
            ))
          }
        </Table.Row>
      </Table.Header>
    );
  }

  return (
    <div className={`width-${width || 100} overflow-auto`}>
      {
        fetching && <TableLoading columns={columns} />
      }
      {
        !fetching && !rows.length && <p>{noDataMessage}</p>
      }
      {
        !fetching && rows.length ?
          <Table className={coloredBg ? 'colored-bg' : ''} size='small' sortable={sortable} celled={celled}>
            {
              sortable ?
                <SortableHeader columns={columns} />
                : renderHeader(columns)
            }
            <Table.Body>
              {children}
            </Table.Body>
          </Table> : ''
      }
    </div>
  )
}

export default TableComponent;