import { Button, Modal } from "semantic-ui-react";
import { ModalHeader } from "./Common";

interface Props {
  hideModal: () => void;
  onClose: () => void;
  onSave: () => void;
  title: string;
  message: any;
  primaryBtnTitle?: string;
  secondaryBtnTitle?: string;
}


function AlertModal(props: Props) {
  const {
    message,
    title,
    secondaryBtnTitle,
    primaryBtnTitle,
    hideModal
  } = props;

  return (
    <Modal
      size="tiny"
      dimmer
      open
      onClose={hideModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      {title && <ModalHeader title={title} toggle={hideModal} />}
      <Modal.Content className="margin-t-16">
        {message}
      </Modal.Content>
      <Modal.Actions>
        {secondaryBtnTitle && <Button onClick={props.onClose}>
          {secondaryBtnTitle || "Cancel"}
        </Button>
        }

        <Button primary onClick={props.onSave}>
          {primaryBtnTitle || "OK"}
        </Button>

      </Modal.Actions>
    </Modal>
  );
}
export default AlertModal;
