import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import "./PageNotFound.scss";

const PageNotFound = () => {

    const navigate = useNavigate();

    const navigateBack = () => {
        navigate(-1);
    }

    return (
        <div className="not-found">
            <h1>404</h1>
            <p>Page Not Found</p>
            <Button onClick={() => navigateBack()} size='tiny' primary>Back</Button>
        </div>
    );
  };
  
  export default PageNotFound;