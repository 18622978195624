import Breadcrumb from "components/Breadcrumb";
import { FormDropdown, FormInput } from "components/FormInputs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "semantic-ui-react";
import { createInitiative } from "store/list";
import { requestInitiativeDropdown } from "store/rext";
import { getInitiativeDropdown } from "store/selectors";
import { transformItemToDropdownOption } from "utils/global";

const AddInitiative = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState<boolean>(false);
    const { register, handleSubmit, setValue, getValues, formState: { errors }, trigger } = useForm();

    const { data: dropDownData } = useSelector(getInitiativeDropdown);

    const Options = transformItemToDropdownOption(dropDownData?.dropdowns?.initiativesDropdowns || [])

    const onSubmit = handleSubmit(data => {
        dispatch(createInitiative(data));
        setLoading(true);
        navigate(-1);
    });

    useEffect(() => {
        dispatch(requestInitiativeDropdown())
    }, [dispatch])

    return (
        <div className="padding-l-14 padding-r-14 margin-t-8">
            <Breadcrumb />

            <div className="width-30">
                <Form size="small" onSubmit={onSubmit}>
                    <FormDropdown
                        label='Practice Area'
                        name='paId'
                        options={Options}
                        errors={errors}
                        register={register}
                        validation={{ required: true }}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                    />

                    <FormInput
                        label='Initiative Text'
                        name='initiativeText'
                        errors={errors}
                        register={register}
                        validation={{ required: true }}
                    />

                    <div className="margin-t-8 flex flex-justify-end">
                        <Button type='submit' loading={isLoading} size='tiny' primary>Create</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default AddInitiative;