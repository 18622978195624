import AppRoutes from './appRoutes/AppRoutes';
import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { ToastContainer } from 'react-toastify';
import { Security } from '@okta/okta-react';
import { getOkatAuth } from './utils/oktaAuth';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import ModalManager from './containers/ModalManager';
import { requestApplicationContext } from './store/actions';
import { getOktaAuthState } from './store/selectors';

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const oktaAuth = useSelector(getOktaAuthState);
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  useEffect(() => {
    dispatch(requestApplicationContext(getOkatAuth()))
  }, [])

  return (
    <Fragment>
      {
        oktaAuth && <div className='page-container'>
          {
            <Security oktaAuth={getOkatAuth()} restoreOriginalUri={restoreOriginalUri}>
              <AppRoutes />
            </Security>
          }
        </div>
      }
      <ModalManager />
      <ToastContainer />
    </Fragment>
  );
}

export default App;
