import { IPeopleUser } from "store/reducers/people";

export function getNewProjectFormValue(loggedInUserName: IPeopleUser, isAddProject: boolean, additionalData: any, paId: string): any {
    return {
        actualCompletiondate: "",
        additionalComments: "",
        anticipatedCompletiondate: "",
        budgetExpense: "",
        budgetTime: "",
        budgetTotal: "",
        coLead: [{
            partner: ''
        }],
        coSponsored: "N",
        contributionExpense: "",
        contributionTime: "",
        contributionTotal: "",
        funding: isAddProject ? [
            { type: 'Sponsoring PA', paName: paId, timeFunded: 100, expenseFunded: 100, billingOfficer: additionalData?.billingOfficer || '', revenueCreditMdp: additionalData?.revenueCreditMdp || '', chargeCode: 'unassigned', status: '2' },
        ] : [
            { type: 'Sponsoring PA', paName: paId, timeFunded: 100, expenseFunded: 100, billingOfficer: additionalData?.billingOfficer || '', revenueCreditMdp: additionalData?.revenueCreditMdp || '', chargeCode: 'unassigned', status: '2' },
        ],
        initiative: "",
        investmenttype: "",
        clientName: "",
        clientContribution: "",
        leadPartner: "",
        pa: "",
        paManagementContact: loggedInUserName,
        projectDeliverables: [],
        projectDescription: "",
        projectLength: 0,
        projectManager: "",
        projectName: "",
        keyProgramElements: "",
        projectStaffed: false,
        projectType: "2",
        competitorContext: "",
        marketingPlan: "",
        expectedOutcome: "",
        futureGrowth: "",
        publicRequirements: "",
        staff: [],
        startDate: "",
        steeringCommitte: [{
            steeringCommitte: ''
        }],
        stuffingPriorityPa: "",
        stuffingUrgency: ""
    }
}

export function transformDropdownOptions(data: Array<any>): any {
    if (data === null || data === undefined || data.length === 0) {
        return [];
    }

    return data.map((item: any) => {
        return { key: item.id, text: item.text, value: item.id };
    });
}

export function tranformProjectData(data: any, editable: boolean, editData: any, viewable: boolean): any {
    return {
        "generalInformation": {
            "projectName": data.projectName,
            "coSponsored": data.coSponsored,
            "initiative": Number(data.initiative),
            "projectType": data.projectType ? Number(data.projectType) : null,
            "typeOfInvestment": data?.investmenttype ? Number(data.investmenttype) : null,
            "clientName": data.clientName,
            "clientContribution": data.clientContribution,
            "paManagementId": Number(data.paManagementContact.id),
            "paManagementName": data.paManagementContact.preferredName,
            "paManagementEmail": data.paManagementContact.email,
            "proposalDate": editData?.generalInformation?.proposalDate ? editData?.generalInformation?.proposalDate : null,
            "approvalDate": editData?.generalInformation?.approvalDate ? editData?.generalInformation?.approvalDate : null,
            "openSinceDate": editData?.generalInformation?.openSinceDate ? editData?.generalInformation?.openSinceDate : null,
            "sponsoringPas": buildFundingJson(data, editable),
            "requestStatus": editData?.generalInformation?.requestStatus ? editData?.generalInformation?.requestStatus : null,
        },
        "projectDescriptionAndDeliverable": {
            "projectDescription": data.projectDescription,
            "keyProgramElements": data?.keyProgramElements || "",
            "competitorContext": data?.competitorContext || "",
            "marketingPlan": data?.marketingPlan || "",
            "expectedOutcome": data?.expectedOutcome || "",
            "futureGrowth": data?.futureGrowth || "",
            "anticipatedStartDate": formatDateToTimestamp(data.anticipatedStartDate),
            "anticipatedCompletionDate": formatDateToTimestamp(data.anticipatedCompletiondate),
            "projectLength": data.projectLength ? Number(data.projectLength) : null,
            "actualCompletionDate": formatDateToTimestamp(data.actualCompletiondate),
            "proposedDeliverables": buildDeliverableData(data?.projectDeliverables, editable)
        },
        "leadershipAndFinancials": {
            "coLeadPartners": buildCoLeadPartnersJson(data.coLead, data.leadPartner, editable),
            "proposedSteeringCommittees": data.steeringCommitte[0].steeringCommitte !== '' ? buildSteeringCommitteJson(data.steeringCommitte, editable) : [],
            "projectManager": data.projectManager ? data.projectManager?.id : null,
            "projectManagerName": data.projectManager ? data.projectManager?.preferredName : null,
            "projectManagerEmail": data.projectManager ? data.projectManager?.email : null,
            "additionalComments": data.leadershipAdditionalComments,
            "budgetTime": data.budgetTime ? data.budgetTime : '',
            "budgetExpenses": data.budgetExpense ? data.budgetExpense : '',
            "budgetTotal": data.budgetTotal ? data.budgetTotal : '',
            "paSponsorFinancials": null,
        },
        "staffingNeed": {
            "projectIsCompletelyStaffed": data?.projectStaffed || false,
            "staffingUrgency": Number(data.staffingUrgency),
            "staffingPriority": Number(data.stuffingPriorityPa),
            "publicRequirements": data.publicRequirements || "",
            "purposefulColocation": data.purposefulColocation || "",
            "staffRequests": data.projectStaffed ? [] : getStaffingRequest(data.staff, editable, 'save'), // CHECK
        },
        // "portfolioInformation": {
        //     "commercialCategoryId": Number(data.commercialCategory),
        //     "directRevenueImpact": Number(data.directRevenueImpact),
        //     "sizeOfInvestment": Number(data.sizeOfInvestment),
        //     "stageOfDevelopment": Number(data.stageOfDevelopment),
        //     "ideaOrigin": Number(data.ideaOrigin),
        //     "ideaOriginOther": data.ideaOriginOther || null,
        //     "clientParticipation": data.clientParticipation,
        //     "clientParticipationOther": data.clientParticipationOther || null,
        //     "pdtTypeId": Number(data.pdtType),
        //     "pdtStageId": Number(data.pdtStage),
        //     "paInitiativeId": Number(data.paInitiative),
        //     "additionalComments": data.portfolioAdditionalComments
        // }
    }
}

function buildFundingJson(data: any, isEditable: boolean): any {
    const mappedDataArr =  data?.funding.map((item: any, index: number) => {
        let mappedItem: any = {
            isPrimary: index === 0 ? true : false,
            timeFunded: item?.timeFunded,
            expenseFunded: item.expenseFunded,
            chargeCode: item.chargeCode,
            status: Number(item.status),
            paName: item.paName !== "" ? Number(item.paName) : '',
            // bilingOfficerId: item.billingOfficer ? Number(item.billingOfficer?.id) : '',
            // bilingOfficerName: item.billingOfficer ? item.billingOfficer?.preferredName : '',
            // bilingOfficerEmail: item.billingOfficer ? item.billingOfficer?.email : '',
            bilingOfficerName: item.billingOfficer ? item.billingOfficer : '',
            revenueCreditMdp: item.revenueCreditMdp ? item.revenueCreditMdp : '',
        }
        if (isEditable && item?.id) {
            mappedItem.id = item?.id || null;
        }
        if (item?.status === '3') {
            mappedItem.reasonForDenial = item?.reasonForDenial || null;
        }
        return mappedItem;
    });
    if(data.coSponsored === 'N') {
        return mappedDataArr.length > 0 ? [mappedDataArr[0]] : [];
    } else {
        return mappedDataArr;
    }
}

function buildDeliverableData(data: any, isEditable: boolean) {
    return data?.map((item: any) => {
        let mappedItem: any = {
            deliverable: item.deliverable,
            timelinesDate: item.timelinesDate ? formatDateToTimestamp(item.timelinesDate) : null
        }
        if (isEditable && item?.id) {
            mappedItem.id = item?.id || null;
        }
        return mappedItem;
    })
}

function buildCoLeadPartnersJson(coLeadData: any, leadPartner: any, isEditFeature: boolean): any {
    const leadPartnerObj = { partner: leadPartner };
    let leadPartnerData = [];
    let coLeadPartnerData = [];
    if (Object.keys(leadPartner).length !== 0) {
        leadPartnerData = buildPartnerObj(Array(leadPartnerObj), 'lead', isEditFeature);
    }
    if (coLeadData?.length && coLeadData[0]?.partner !== '') {
        coLeadPartnerData = buildPartnerObj(coLeadData, '', isEditFeature);
    }
    const allData = leadPartnerData.concat(coLeadPartnerData);
    return allData;
}

function buildPartnerObj(dataArr: any, type: string, isEdit: boolean) {
    return dataArr.map(((partnerItem: any) => {
        if (Object.keys(partnerItem.partner).length == 0) {
            return null
        }
        const mappedItem: any = {
            isLeadPartner: type === 'lead' ? true : false,
            partnerId: Number(partnerItem?.partner?.partnerId),
            partnerName: partnerItem.partner?.preferredName,
            partnerEmail: partnerItem.partner?.email
        };
        if (isEdit) {
            if (partnerItem?.partner.id !== undefined) {
                mappedItem.id = partnerItem?.partner.id || null;
            }
        }
        return mappedItem;
    }
    )).filter(Boolean)
}

function buildSteeringCommitteJson(data: any, isEditable: boolean): any {
    return data.map((item: any) => {
        if (Object.keys(item.steeringCommitte).length == 0) {
            return null
        }
        let mappedItem: any = {
            memberId: Number(item.steeringCommitte?.hrId),
            memberName: item.steeringCommitte?.preferredName,
            memeberEmail: item.steeringCommitte?.email
        }
        if (isEditable) {
            if (item?.steeringCommitte?.id !== undefined) {
                mappedItem.id = Number(item?.steeringCommitte?.id) || null;
                mappedItem.memberId = Number(item?.steeringCommitte?.memberId) || null;
            }
            if (item?.steeringCommitte?.hrId !== undefined) {
                mappedItem.memberId = Number(item.steeringCommitte?.hrId);
            }
        }
        return mappedItem;
    }).filter(Boolean)
}

function formatDateToTimestamp(dateString: Date) {
    if (!dateString) {
        return null;
    }
    const date = new Date(dateString);
    const timestamp = date.getTime();
    return timestamp;
}

export function getEditProjectFormValue(loggedInUserName: IPeopleUser, data: any, additionalData: any): any {
    if (Object.keys(data).length === 0) {
        return;
    }
    return {
        projectName: data.generalInformation.projectName,
        coSponsored: data.generalInformation.coSponsored,
        initiative: data.generalInformation.initiative?.toString(),
        projectType: data.generalInformation.projectType?.toString(),
        investmenttype: data.generalInformation.typeOfInvestment?.toString(),
        clientName: data.generalInformation.clientName,
        clientContribution: data.generalInformation.clientContribution ? getFormattedNumber(data.generalInformation.clientContribution) : '',
        funding: buildSponsoringPasData(data.generalInformation.sponsoringPas, additionalData),
        // paManagementContact: loggedInUserName,
        paManagementContact: buildPaManagementContact(data.generalInformation),

        projectDescription: data.projectDescriptionAndDeliverable.projectDescription,
        // projectObjective: data.projectDescriptionAndDeliverable.projectObjective,
        keyProgramElements: data.projectDescriptionAndDeliverable?.keyProgramElements || "",
        competitorContext: data.projectDescriptionAndDeliverable?.competitorContext || "",
        marketingPlan: data.projectDescriptionAndDeliverable?.marketingPlan || "",
        expectedOutcome: data.projectDescriptionAndDeliverable?.expectedOutcome || "",
        futureGrowth: data.projectDescriptionAndDeliverable?.futureGrowth || "",
        anticipatedStartDate: new Date(data.projectDescriptionAndDeliverable.anticipatedStartDate),
        anticipatedCompletiondate: new Date(data.projectDescriptionAndDeliverable.anticipatedCompletionDate),
        projectLength: data.projectDescriptionAndDeliverable.projectLength,
        actualCompletiondate: data.projectDescriptionAndDeliverable.actualCompletionDate,
        projectDeliverables: data.projectDescriptionAndDeliverable.proposedDeliverables,

        leadPartner: buildCoLeadData(data.leadershipAndFinancials.coLeadPartners, 'lead'),
        coLead: buildCoLeadData(data.leadershipAndFinancials.coLeadPartners, ''),
        steeringCommitte: getProposedSteeringCommitte(data.leadershipAndFinancials.proposedSteeringCommittees),
        projectManager: getProjectManager(data.leadershipAndFinancials),
        leadershipAdditionalComments: data.leadershipAndFinancials.additionalComments,
        // actualExpense: data.leadershipAndFinancials.actualExpenses,
        // actualTime: data.leadershipAndFinancials.actualTime,
        // actualTotal: data.leadershipAndFinancials.actualTotal,
        budgetExpense: getFormattedNumber(data.leadershipAndFinancials.budgetExpenses),
        budgetTime: getFormattedNumber(data.leadershipAndFinancials.budgetTime),
        budgetTotal: getFormattedNumber(data.leadershipAndFinancials.budgetTotal),
        contributionExpense: "",
        contributionTime: "",
        contributionTotal: "",

        staff: getStaffingRequest(data.staffingNeed.staffRequests, true, ''),
        stuffingPriorityPa: String(data.staffingNeed?.staffingPriority),
        staffingUrgency: String(data.staffingNeed?.staffingUrgency),
        projectStaffed: data.staffingNeed.projectIsCompletelyStaffed,
        publicRequirements: data.staffingNeed.publicRequirements || "",
        purposefulColocation: String(data.staffingNeed.purposefulColocation) || "",

        // commercialCategory: dropdownNullCheck(data.portfolioInformation.commercialCategoryId),
        // directRevenueImpact: dropdownNullCheck(data.portfolioInformation.directRevenueImpact),
        // sizeOfInvestment: dropdownNullCheck(data.portfolioInformation.sizeOfInvestment),
        // stageOfDevelopment: dropdownNullCheck(data.portfolioInformation.stageOfDevelopment),
        // pdtType: dropdownNullCheck(data.portfolioInformation.pdtTypeId),
        // ideaOrigin: dropdownNullCheck(data.portfolioInformation.ideaOrigin),
        // ideaOriginOther: data.portfolioInformation.ideaOriginOther,
        // clientParticipation: dropdownNullCheck(data.portfolioInformation.clientParticipation),
        // clientParticipationOther: data.portfolioInformation.clientParticipationOther,
        // pdtStage: dropdownNullCheck(data.portfolioInformation.pdtStageId),
        // paInitiative: dropdownNullCheck(data.portfolioInformation.paInitiativeId),
        // portfolioAdditionalComments: data.portfolioInformation.additionalComments,
    }
}

function dropdownNullCheck(item: any) {
    if (item !== null) {
        return item.toString();
    }
    return null;
}

function buildPaManagementContact(generalInfoData: any) {
    return {
        id: generalInfoData.paManagementId,
        preferredName: generalInfoData.paManagementName,
        email: generalInfoData.paManagementEmail
    }
}

function buildSponsoringPasData(data: any, revenueData: any): any {
    
    const sortedData = data.sort((a: any, b: any) => {
        return (b.isPrimary ? 1 : 0) - (a.isPrimary ? 1 : 0);
    });

    return sortedData?.map((item: any, index: number) => {
        let mappedData: any = {
            type: index === 0 ? 'Sponsoring PA' : 'Co-Sponsoring PA',
            paName: String(item.paName),
            timeFunded: item.timeFunded,
            expenseFunded: item.expenseFunded,
            // billingOfficer: { id: item.bilingOfficerId, preferredName: item.bilingOfficerName, email: item.bilingOfficerEmail },
            billingOfficer: item.bilingOfficerName,
            chargeCode: item.chargeCode,
            status: String(item.status),
            revenueCreditMdp: item.revenueCreditMdp,
            id: item.id
        }
        if (item.status === 3) {
            mappedData.reasonForDenial = item.reasonForDenial || '';
        }
        return mappedData;
    });
}

function buildCoLeadData(data: any, type: string): any {
    if (type === 'lead') {
        let dataArr = getPartnerDataArr(data);
        let leadPartner = dataArr.filter((item: any) => item.partner?.isLeadPartner == true);
        if (leadPartner?.length) {
            return leadPartner[0]?.partner
        } else {
            return {}
        }
    } else {
        let dataArr = getPartnerDataArr(data)
        let filterCoLead = dataArr.filter((item: any) => item.partner?.isLeadPartner == false);
        return filterCoLead;
    }
}

function getPartnerDataArr(data: any) {
    return data.map((item: any) => {
        let partnerItem = {
            partner: {
                id: item.id,
                partnerId: item.partnerId,
                email: item.partnerEmail,
                preferredName: item.partnerName,
                isLeadPartner: item.isLeadPartner
            }
        }
        return partnerItem;
    });
}

function getProposedSteeringCommitte(data: any) {
    if (data.length === 0) {
        return [{}];
    }
    return data.map((item: any) => {
        return {
            steeringCommitte: {
                id: item.id,
                memberId: item.memberId,
                preferredName: item.memberName,
                email: item.memeberEmail
            }
        }
    });
}

function getProjectManager(data: any) {
    return {
        id: data.projectManager ? data.projectManager : null,
        preferredName: data.projectManagerName ? data.projectManagerName : null,
        email: data.projectManagerEmail ? data.projectManagerEmail : null,
    }
}

function getFormattedNumber(number: string) {
    const replaceComma = number?.replace(/,/g, '');
    const numberValue = parseFloat(replaceComma);
    const formatted = formatUSD(numberValue);
    return formatted;
}

function getStaffingRequest(data: any, isEdit: boolean, type: string) {
    if (data.length === 0) {
        return [];
    }
    return data.map((item: any) => {
        let mappedData: any = {
            "capacity": item.capacity,
            "cohort": type === 'save' ? Number(item.cohort) : String(item.cohort),
            // "pref1WithinRegion1": item.pref1WithinRegion1,
            // "pref1WithinRegion2": item.pref1WithinRegion2,
            // "pref2WithinRegion1": item.pref2WithinRegion1,
            // "pref2WithinRegion2": item.pref2WithinRegion2,
            "projectLength": item.projectLength,
            "projectStaff": item.projectStaff,
            "regionPref1": type === 'save' ? Number(item.regionPref1) : String(item.regionPref1),
            "regionPref2": type === 'save' ? Number(item.regionPref2) : String(item.regionPref2),
        }

        if (isEdit) {
            mappedData.id = item.id
        }
        return mappedData;
    });
}

export function formatUSD(number: any) {
    if (isNaN(number)) return '';
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(number);
};