import { OktaAuth } from "@okta/okta-auth-js";
import { APPLICATION_CONTEXT_ACTION, DEFAULT_INITIAL_ACTION_STATE, StandardAction } from "store/actions";

export interface ApplicationContextState {
  baseUrl: string;
  okatAuth: OktaAuth;
  currentLanguage: string;
}

const INITIAL_STATE: ApplicationContextState = {
  baseUrl: ``,
  okatAuth: undefined!,
  currentLanguage: "en"
};

const applicationContextReducer = (state: ApplicationContextState = INITIAL_STATE, action: StandardAction = DEFAULT_INITIAL_ACTION_STATE): ApplicationContextState => {
  const { type, payload } = action;
  if(type === APPLICATION_CONTEXT_ACTION) {
    return {...state, okatAuth: payload};
  } else {
    return state
  }
}

export default applicationContextReducer;