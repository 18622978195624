import { IListAction, IListInfo, IListItems } from './interface';

export const defaultListInfo: IListInfo = {
  list: {
    error: false,
    fetching: false,
    message: undefined!
  },
  operation: {
    error: false,
    fetching: false,
    message: undefined!
  }
};


export const defaultListItems: IListItems = {
  ids: [],
  raw: {}
};

export const defaultListAction: IListAction = {
  meta: {
    actions: {} as any,
    keys: {} as any
  },
  payload: {},
  type: ""
}