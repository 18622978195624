import { FormInput } from "components/FormInputs";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button, Checkbox, Divider, Form, Modal } from "semantic-ui-react";
import { getAllBCGUsersByName } from "store/list";
import { getSearchBCGUsers } from "store/selectors";

interface Props {
    hideModal: () => void;
    onSelect: (staff: any) => void;
    type: string;
}

const dataArr = [
    { id: 1, label: "Staff", fpaIpa: false },
    { id: 2, label: "Cohort", fpaIpa: false },
    { id: 3, label: "Fpa", fpaIpa: false },
    { id: 4, label: "Ipa", fpaIpa: false },
    { id: 5, label: "Item", fpaIpa: false },
]

const FpaIpaModal = (props: Props) => {
    const dispatch = useDispatch();

    const { data: searchUserState, fetching } = useSelector(getSearchBCGUsers);

    const {
        hideModal
    } = props;
    const { register, handleSubmit, reset, watch, control, setValue, getValues } = useForm();

    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState(dataArr);

    const { fields, append, remove } = useFieldArray({
        name: "checkboxData",
        control
    });

    const clearValue = () => {
        reset({
            search: '',
            checkboxData: dataArr
        });
        // props.onSelect(null);
        // props.hideModal()
    }

    const onSubmit = handleSubmit(data => {
        console.log("data--", data);
        props.onSelect(data.checkboxData);
    });

    const watchFormFields = useWatch({
        control,
        name: ['search', 'checkboxData'],
    });
    console.log('watch---', watchFormFields[1])

    const renderChechbox = (item: any, index: number) => {
        const { label } = item;
        return (
            <Checkbox
                className="checkbox-alignment"
                key={index}
                name={`checkboxData.${index}.fpaIpa`}
                label={label}
                checked={watchFormFields[1][index]?.fpaIpa}
                onClick={(e, { name, checked }) => {
                    setValue(name || '', checked);
                    console.log('on-change--', name)
                }}
            />
        )
    }

    useEffect(() => {
        const filteredItems = dataArr.filter((item) =>
            item.label?.toLowerCase().includes(watchFormFields[0]?.toLowerCase())
        );
        // Set the filtered items to checkboxData while preserving checked values
        const updatedCheckboxData = filteredItems.map((filteredItem) => {
            const existingItem = watchFormFields[1].find((item: any) => item.label === filteredItem.label);
            if (existingItem) {
                return existingItem;
            }
            return { label: filteredItem.label, fpaIpa: false };
        });
        setValue('checkboxData', updatedCheckboxData);
    }, [watchFormFields[0], setValue]);

    useEffect(() => {
        setFilteredData(dataArr);
        setValue('checkboxData', dataArr)
    }, []);

    return (
        <Modal
            size="tiny"
            dimmer
            open
            onClose={hideModal}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Header>
                <div className="flex flex-justify-between">
                    <div className="flex-justify-start flex-align-self-center">
                        <h5 className="margin-0">FPA / IPA</h5>
                    </div>
                    <div className="flex-justify-end">
                        <Button basic className="cursor-pointer" size='mini' onClick={hideModal}>close &#10006;</Button>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Content>
                <p className="margin-0 text-bold text-md">Search</p>

                <Form className="margin-b-6" size="small" onSubmit={onSubmit}>
                    <div className="width-100">
                        <div className="margin-t-4">
                            <FormInput
                                name='search'
                                register={register}
                            />
                        </div>

                        <Divider />

                        {fields.length ? (
                            fields.map(renderChechbox)
                        ) : null}

                        <div className="margin-t-6">
                            <Button size='mini' type="button" onClick={clearValue}>
                                Clear All
                            </Button>

                            <Button size='mini' primary type="submit">
                                Apply
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal.Content>

        </Modal>
    )
}

export default FpaIpaModal;