import { combineReducers } from 'redux';
import modalState, { ModalState } from "./modal";
import applicationContextReducer, { ApplicationContextState } from "./applicationContext"
import { IRextReducer } from 'baseProvider/rext';
import { appRext, initiativeDropdownRext, addProjectDropdownRext, homeDropdownRext, searchBCGUsers, editProjectDetailsRext, projectHistoryDetails, projectSubmitToPaDetails, projectGetEmailCode, adminChargeCodeRext, addEditProjectDetailsRext, requestSaveChangesRext, requestSubmitToFinanceRext, requestUpdateFinanceRext, projectStatusDropdownRext, addChargeCodeStatusRext, getRevenueCreditRext } from 'store/rext';
import { IListReducer } from 'baseProvider/list';
import peopleReducer, { PeopleState } from "./people";
import { adminAppSettings, adminList, adminPaDetails, adminPaList, homeList, initiativeList, internsList, projectHistoryList, projectList } from 'store/list';

export type { ModalState } from "./modal";

export interface ApplicationState {
  modalState: ModalState;
  applicationContext: ApplicationContextState;
  appState: IRextReducer;
  adminListState: IListReducer;
  initiativeDropdownState: IRextReducer;
  internsList: IListReducer;
  addProjectDropdownState: IRextReducer;
  initiativeListState: IListReducer;
  peopleState: PeopleState;
  homeDropdownState: IRextReducer;
  homeListState: IListReducer;
  adminPAListState: IListReducer;
  adminPADetailsState: IListReducer;
  serachBcgUserState: IRextReducer;
  editProjectDetailsState: IRextReducer;
  addEditProjectDetailsState: IRextReducer;
  projectListState: IListReducer;
  projectHistoryListState: IRextReducer;
  projectSubmitToPaState: IRextReducer;
  adminAppSettingsState: IListReducer;
  projectSaveChangesState: IRextReducer;
  projectSubmitToFinanceState: IRextReducer;
  projectFinanceStatusState: IRextReducer;
  projectEmailCodeState: IRextReducer;
  adminChargeCodeState: IRextReducer;
  chargeCodeStatusDropdownState: IRextReducer;
  addChargeCodeState: IRextReducer;
  getRevenueCreditState: IRextReducer;
}

const rootReducer = () => combineReducers<ApplicationState>({
  modalState: modalState,
  applicationContext: applicationContextReducer,
  appState: appRext.reducers,
  adminListState: adminList.reducers,
  initiativeDropdownState: initiativeDropdownRext.reducers,
  internsList: internsList.reducers,
  initiativeListState: initiativeList.reducers,
  peopleState: peopleReducer,
  addProjectDropdownState: addProjectDropdownRext.reducers,
  homeDropdownState: homeDropdownRext.reducers,
  homeListState: homeList.reducers,
  adminPAListState: adminPaList.reducers,
  adminPADetailsState: adminPaDetails.reducers,
  serachBcgUserState: searchBCGUsers.reducers,
  editProjectDetailsState: editProjectDetailsRext.reducers,
  addEditProjectDetailsState: addEditProjectDetailsRext.reducers,
  projectListState: projectList.reducers,
  projectHistoryListState: projectHistoryDetails.reducers,
  projectSubmitToPaState: projectSubmitToPaDetails.reducers,
  adminAppSettingsState: adminAppSettings.reducers,
  projectSaveChangesState: requestSaveChangesRext.reducers,
  projectSubmitToFinanceState: requestSubmitToFinanceRext.reducers,
  projectFinanceStatusState: requestUpdateFinanceRext.reducers,
  projectEmailCodeState: projectGetEmailCode.reducers,
  adminChargeCodeState: adminChargeCodeRext.reducers,
  chargeCodeStatusDropdownState: projectStatusDropdownRext.reducers,
  addChargeCodeState: addChargeCodeStatusRext.reducers,
  getRevenueCreditState: getRevenueCreditRext.reducers
});

export default rootReducer;