import Breadcrumb from "components/Breadcrumb";
import TableComponent from "components/TableComponent";
import { Column } from "interface";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { requestPADetails } from "store/list";
import { useSelector } from "react-redux";
import { getAdminPADetails } from "store/selectors";
import UserDetails from "./UserDetails";

const columns: Column[] = [
    { name: 'HR ID', value: 'staff_id', sort: false },
    { name: 'Member Name', value: 'adGroupName', sort: false },
    { name: 'Alumni?', value: 'alumni', sort: false },
    { name: 'Office', value: 'office', sort: false },
    { name: 'Position', value: 'position', sort: false }
];

const PADetails = () => {
    const dispatch = useDispatch();
    let [searchParams] = useSearchParams();
    let [query] = useState({
        PA_ID: searchParams.get("PA_ID") || '',
        PA_NAME: searchParams.get("PA_NAME") || '',
        AD_GROUP: searchParams.get("AD_GROUP") || ''
    });

    const { data: PaDetails, fetching: paDetailsfetching } = useSelector(getAdminPADetails);

    const renderRows = (item: any) => {
        const { hrId, memberName } = item;
        
        return (
            <UserDetails key={hrId} hrId={hrId} memberName={memberName} />
        )
    }

    useEffect(() => {
        dispatch(requestPADetails(query.PA_ID));
    }, [dispatch, query]);

    return (
        <div className="padding-l-14 padding-r-14 margin-t-8">
            <Breadcrumb />

            <div className="padding-t-3">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={2}>
                            <p><span className="text-bold">PA Name: </span> {query.PA_NAME}</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <p><span className="text-bold">AD Group Name: </span> {query.AD_GROUP}</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>

            <div className="margin-t-6">
                <TableComponent noDataMessage="There are no items." sortable={false} celled={true}
                    fetching={paDetailsfetching || false} columns={columns} rows={PaDetails}>
                    {
                        PaDetails?.map(renderRows)
                    }
                </TableComponent>
            </div>
        </div>
    )
}

export default PADetails;