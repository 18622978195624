import { Link } from "react-router-dom";

const menuItems = [
    { id: 1, name: 'List PA AD Groups and Approvers', link: 'pa-list' },
    { id: 2, name: 'List Admins', link: 'list-admins' },
    { id: 3, name: 'Super Admin List', link: 'list-interns' },
    { id: 4, name: 'List Initiatives', link: 'list-initiatives' },
    { id: 5, name: 'Add/Update Charge Code', link: 'charge-code' },
    { id: 6, name: 'View/Update App Settings', link: 'app-settings' }
]

const AdminOptions = () => {
    return (
        <div className="padding-l-14 padding-r-14 margin-t-8">
            <h3 className="text-primary-color">PA Innovation - Admin Options</h3>

            <ul>
                {
                    menuItems.map(menu => (
                        <li key={menu?.id} className="text-x1lg text-primary-color padding-t-2 padding-b-2">
                            <Link to={`${menu?.link}`} className="text-primary-color text-underline text-hover-underline text-hover-primary">
                                {menu?.name}
                            </Link>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default AdminOptions;