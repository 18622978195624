import { Fragment } from "react";
import { Form } from "semantic-ui-react";
import { FormErrors } from "./FormErrors";

export function FormInput({ onChange, ...props }: any) {

    const { label, name, type = 'text', errors, register, validation, disabled, min, max, message, readOnly, value, detectChange, customClass } = props;

    return (
        <Fragment>
            <Form.Field error={errors && !!errors[name]}>
                <label className={validation && validation.required && 'required'}>{label}</label>
                <input
                className={customClass}
                    placeholder={label}
                    type={type}
                    disabled={disabled}
                    min={min}
                    max={max}
                    readOnly={readOnly}
                    value={value}
                    step="any"
                    {...register(name, validation)}
                    onInput={(e: any) => detectChange && onChange(e)}
                />
                {
                    errors &&
                    <FormErrors
                        label={label}
                        name={name}
                        errors={errors}
                    />
                }
                {
                    message && <small>{message}</small>
                }
            </Form.Field>
        </Fragment>
    );
}
