import Breadcrumb from "components/Breadcrumb";
import { FormInput } from "components/FormInputs";
import { Button, Form } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import UpdateProjectData from "./UpdateProjectData";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAdminChargeCodeState } from "store/selectors";
import { requestAdminChargeCode } from "store/rext";

const AddChargeCode = () => {
    const dispatch = useDispatch();

    const { register, handleSubmit } = useForm();
    const [isUpdate, setUpdate] = useState<boolean>(false);

    const { data, fetching } = useSelector(getAdminChargeCodeState);

    const onSubmit = handleSubmit(data => {
        if (data.lookUp) {
            setUpdate(true);
            dispatch(requestAdminChargeCode(data.lookUp));
        }
    });

    return (
        <div className="padding-l-14 padding-r-14 margin-t-8">
            <Breadcrumb />

            <div className="width-60 margin-t-12">
                <Form size="small" onSubmit={onSubmit}>
                    <div className="flex flex-row">
                        <div className="margin-r-2 flex-align-self-center">
                            <b>Project ID</b> (eg 10114) or <b>Charge Code</b> (eg 12345-67):
                        </div>

                        <div className="flex-align-self-center">
                            <FormInput name='lookUp' register={register} />
                        </div>

                        <div className="margin-l-6 flex-align-self-center">
                            <Button type='submit' loading={fetching || false} size='tiny' primary>Look Up</Button>
                        </div>
                    </div>
                </Form>

                {
                    Object.keys(data).length !== 0 && !fetching  && <UpdateProjectData data={data} />
                }
            </div>
        </div>
    )
}

export default AddChargeCode;