
const Footer = () => {

    return (
        <footer>
            <div className="footer">

            </div>
        </footer>
    );
}

export default Footer;