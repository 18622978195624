import { LoginCallback } from "@okta/okta-react";
import AdminOptions from "containers/AdminOptions";
import AddProject from "containers/AddProject";
import { Route, Routes } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import PageNotFound from "../containers/NotFound/PageNotFound";
import Projects from "../containers/Projects";
import { SecureRoute } from "../HOC/SecureRoute";
import PAList from "containers/PAList";
import ListAdmins from "containers/ListAdmins";
import ListInterns from "containers/ListInterns";
import ListInitiatives from "containers/ListInitiatives";
import AddInitiative from "containers/ListInitiatives/AddInitiative";
import AppSettings from "containers/AppSettings";
import AddChargeCode from "containers/AddChargeCode";
import PADetails from "containers/PADetails";


const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<SecureRoute />}>
        <Route index element={<Projects />} />
        <Route path="projects" element={<Projects />} />
        <Route path="add-project" element={<AddProject />} />
        <Route path="edit-project" element={<AddProject />} />
        <Route path="view-project" element={<AddProject />} />
        <Route path="admin-options">
          <Route index element={<AdminOptions />} />
          <Route path="pa-list" element={<PAList />} />
          <Route path="pa-list/pa-details" element={<PADetails />} />
          <Route path="list-admins" element={<ListAdmins />} />
          <Route path="list-interns" element={<ListInterns />} />
          <Route path="charge-code" element={<AddChargeCode />} />
          <Route path="list-initiatives">
            <Route index element={<ListInitiatives />} />
            <Route path=":paId" element={<ListInitiatives/>}/>
            <Route path="add-initiative" element={<AddInitiative />} />
          </Route>
          <Route path="app-settings" element={<AppSettings />} />
        </Route>
        <Route path='*' element={<PageNotFound />} />
      </Route>
      <Route path='login/callback' element={<LoginCallback loadingElement={<Loader />} />} />
    </Routes>
  );
};

export default AppRoutes;