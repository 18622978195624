import { Fragment, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

interface TableState {
    column: string;
    direction: 'ascending' | 'descending' | undefined;
}

const SortableHeader = ({ columns }: any) => {

    let [searchParams, setSearchParams] = useSearchParams();
    const [tableState, setTableState]: any = useState<TableState>({
        column: '',
        direction: undefined,
    });

    const addParams = (col: string, dir: string) => {
        const params: any = {};
        searchParams.forEach((value, key) => {
            params[key] = value;
        })

        params.col = col;
        params.dir = dir;
        setSearchParams(params);
    }

    const handleSort = (column: string) => {
        if (column !== tableState?.column) {
            setTableState({
                column: column,
                direction: 'descending'
            });
            addParams(column, 'descending');
            return;
        }
        const newDirection: string = tableState?.direction === 'descending' ? 'ascending' : 'descending';
        setTableState({
            column: column,
            direction: newDirection
        });
        addParams(column, newDirection);
    }
    
    useEffect(() => {
        const column = searchParams.get('col')!;
        const direction = searchParams.get('dir');
        if (column && direction) {
            setTableState({
                column, direction
            });
        } else {
            setTableState(undefined!);
        }
    }, [searchParams]);

    return (
        <Fragment>
            <Table.Header>
                <Table.Row>
                    {
                        columns.map((column: any) => (
                            <Table.HeaderCell key={column?.value} width={column?.width} className={column.sort ? 'text-underline' : 'pointer-no-events'}
                                sorted={column.sort && tableState?.column === column?.value ? tableState?.direction : null}
                                onClick={() => handleSort(column?.value)}
                            >{column?.name}</Table.HeaderCell>
                        ))
                    }
                </Table.Row>
            </Table.Header>
        </Fragment>
    );

}

export default SortableHeader;