import Breadcrumb from "components/Breadcrumb";
import TableComponent from "components/TableComponent";
import { Column } from "interface";
import { Button, Form, Table } from "semantic-ui-react";
import { useFieldArray, useForm } from "react-hook-form";
import { FormInput } from "components/FormInputs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createAppSettings, requestAppSettings } from "store/list";
import { useSelector } from "react-redux";
import { getAdminAppSettings } from "store/selectors";
import { useNavigate } from "react-router-dom";

const columns: Column[] = [
    { name: 'Setting Name', value: 'settingName', sort: false },
    { name: 'Value', value: 'value', sort: false },
    { name: 'Description', value: 'description', sort: false }
];

const AppSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState<boolean>(false);

    const { data: appSettingsData, fetching: appSettingsFetching, operationProgress } = useSelector(getAdminAppSettings);

    const { register, handleSubmit, reset, control } = useForm();

    const { fields } = useFieldArray({
        name: "settings",
        control
    });

    useEffect(() => {
        dispatch(requestAppSettings());
    }, [dispatch]);

    useEffect(() => {
        let arr: any = []
        appSettingsData?.map((el: any) => {
            let item = {
                id: el.id,
                settingName: el.settingName,
                value: el.value,
                description: el.description,
            }
            arr.push(item)
            return arr
        })

        reset({ settings: arr })
    }, [appSettingsData]);

    const renderRows = (item: any, index: number) => {
        return (
            <Table.Row key={index}>
                <Table.Cell className="is-view-mode">
                    <FormInput
                        name={`settings.${index}.settingName`}
                        register={register}
                        readOnly
                    />
                </Table.Cell>
                <Table.Cell>
                    <FormInput
                        name={`settings.${index}.value`}
                        register={register}
                    />
                </Table.Cell>
                <Table.Cell className="is-view-mode">
                    <FormInput
                        name={`settings.${index}.description`}
                        register={register}
                    />
                </Table.Cell>
            </Table.Row>
        )
    }

    const onSubmit = handleSubmit(data => {
        const payload = data?.settings.map((setting: any) => ({
            id: setting.id,
            value: setting.value
        }));
        dispatch(createAppSettings({ settings: payload }));
        setLoading(true);
        setTimeout(() => {
            if(!operationProgress) {
                setLoading(true);
                navigate(-1)
            }
        }, 1000);
    });

    return (
        <div className="padding-l-14 padding-r-14 margin-t-8">

            <Breadcrumb />

            <div className="margin-t-6 width-70">
                <Form size="small" onSubmit={onSubmit}>
                    <TableComponent noDataMessage="There are no items." sortable={false} celled={true}
                        fetching={appSettingsFetching || false} columns={columns} rows={appSettingsData}>
                        {
                            fields?.map(renderRows)
                        }
                    </TableComponent>

                    <div className="margin-t-8 flex flex-justify-end">
                        <Button type='submit' loading={isLoading} size='tiny' primary>Create</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default AppSettings;