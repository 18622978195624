import { Link } from 'react-router-dom';
import './Header.scss';

const Header = () => {

    return (
        <header>
            <div className="header">
                <div className="header-top">
                    <h5>THE BOSTON CONSULTING GROUP</h5>
                </div>
                <div className="header-bottom">
                    <Link to="/">
                        PA Innovation Fund
                    </Link>
                </div>
            </div>
        </header>
    );
}

export default Header;