import UsePeople from 'HOC/UsePeople';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { getPeopleState } from 'store/selectors';

const UserDetails = ({ hrId, memberName }: any) => {
    const { user } = UsePeople(hrId)

    const peopleUserState = useSelector(getPeopleState(hrId));

    return (
        <Table.Row key={hrId}>
            <Table.Cell>{hrId}</Table.Cell>
            <Table.Cell>
                <a href={`https://profiles.navigator.bcg.com/overview?hrid=${hrId}`} target="_blank" className="cursor-pointer">{memberName}</a>
            </Table.Cell>
            <Table.Cell>
                {peopleUserState?.fetching ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        {peopleUserState?.user?.alumni}
                    </>
                )}
            </Table.Cell>
            <Table.Cell>
                {peopleUserState?.fetching ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        {peopleUserState?.user?.homeOffice}
                    </>
                )}
            </Table.Cell>
            <Table.Cell>
                {peopleUserState?.fetching ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        {peopleUserState?.user?.businessTitle}
                    </>
                )}
            </Table.Cell>
        </Table.Row>
    )
}

export default UserDetails