import { Fragment } from "react";

export function FormErrors(props: any) {

    const { label, name, errors } = props;

    return (
        <Fragment>
            {errors[name]?.type === 'required' && <p className="text-form-error">{label} is required</p>}
            {errors[name]?.type === 'pattern' && <p className="text-form-error">{label} is invalid</p>}
        </Fragment>
    );
}
