import { StandardAction, DEFAULT_INITIAL_ACTION_STATE } from "store/actions";
import { MULTI_PEOPLE_API, PEOPLE_API } from "store/actions/people";

export interface PeopleState {
  [key: string]: IPeople
}

export interface IPeople {
  fetching: boolean;
  error: string;
  user: IPeopleUser;
}

export interface IPeopleUser {
  id: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
  birthDate: string;
  businessTitle: string;
  cohortStep: string;
  cohort: string;
  email: string;
  gender: string;
  homeOffice: string;
  homeOfficeId: string;
  homeOfficeRegion: string;
  hostOfficeId: string;
  hostOfficeRegion: string;
  jobCategoryId: string;
  jobFamilyGroupId: string;
  jobFamilyGroupName: string;
  jobTitle: string;
  lastPromotionDate: string;
  lateralHire: boolean;
  preferredFirstName: string;
  preferredLastName: string;
  preferredName: string;
  tbId: string;
  workEmail: string;
  hireDate: string;
  alumni: string;
  latestHireDate: string;
  assistants: Array<{ id: number; preferredFirstName: string; preferredLastName: string; email: string }>,
  workExperience: Array<{ jobTitle: string; startDate: string; endDate: string; company: string; companyLocation: string; }>
  internalJobHistory: Array<{ effectiveDate: string, ineffectiveDate: string }>;
  defaultAllocation: string;
  globalTitle: string;
  pastTerminationDate: string;
  homeOfficeLocation: {
    name: string;
    system: string;
    hierarchies: [{ name: string, type: string }]
  },
  hostOfficeLocation: {
    name: string;
    system: string;
    hierarchies: [{ name: string, type: string }]
  },
  sponsors: Array<{
    name: string;
    practiceArea: string;
    primaryTopic: string
  }>
}

const peopleContextReducer = (state: PeopleState = {}, action: StandardAction = DEFAULT_INITIAL_ACTION_STATE): PeopleState => {
  const { type, payload } = action;
  const { peopleId, user, error, users, peopleIds } = payload || {};
  switch (type) {
    case PEOPLE_API.REQUEST:
      return {
        ...state,
        [peopleId]: {
          fetching: true,
          user: undefined!,
          error: undefined!
        }
      };
    case PEOPLE_API.SUCCESS:
      return {
        ...state,
        [peopleId]: {
          fetching: false,
          user: user,
          error: undefined!
        }
      };
    case PEOPLE_API.FAILURE:
      return {
        ...state,
        [peopleId]: {
          fetching: false,
          user: undefined!,
          error: error
        }
      };

    case MULTI_PEOPLE_API.REQUEST:
      const newPeoples: any = {};
      peopleIds.forEach((userId: string) => {
        if (!state[userId]) {
          newPeoples[userId] = {
            fetching: true,
            user: undefined!,
            error: undefined!
          }
        }
      })
      return {
        ...state,
        ...newPeoples
      }
    case MULTI_PEOPLE_API.SUCCESS:
      const newUsers: any = {};

      users.forEach((newUser: any) => {
        const { id } = newUser;
        newUsers[id] = {
          fetching: false,
          user: newUser,
          error: undefined!
        }
      })
      return {
        ...state,
        ...newUsers
      }
    case MULTI_PEOPLE_API.FAILURE:
      const newPeopleError: any = {};
      peopleIds.forEach((userId: string) => {
        newPeopleError[userId] = {
          fetching: false,
          user: undefined!,
          error: error
        }
      })
      return {
        ...state,
        ...newPeopleError
      }
    default:
      return state;
  }
}

export default peopleContextReducer;