import { Grid, Table } from "semantic-ui-react";
import { transformDate } from "utils/global";

interface IHistory {
    id: number;
    date: number;
    user: string;
    statusOld: any;
    statusNew: any;
}

interface Props {
    data: Array<IHistory>
}

const History = ({ data }: Props) => {

    const renderRows = (item: IHistory, index: number) => {
        return (
            <>
                <Table.Row key={index + 1}>
                    <Table.Cell>
                        {transformDate(item.date)}
                    </Table.Cell>
                    <Table.Cell>
                        {item.user}
                    </Table.Cell>
                    <Table.Cell>
                        {item.statusOld}
                    </Table.Cell>
                    <Table.Cell>
                    {item.statusNew}
                    </Table.Cell>
                </Table.Row>
            </>
        )
    }

    return (
        <div className="border-3 border-primary-color">
            <h5 className="text-background-primary text-white-color padding-1 margin-0">History</h5>
            <div className="padding-4 margin-4">

                <Grid>
                    <Grid.Row columns={12}>
                        <Table size="small">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                    <Table.HeaderCell>User</Table.HeaderCell>
                                    <Table.HeaderCell>Status (old)</Table.HeaderCell>
                                    <Table.HeaderCell>Status (new)</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    data?.map(renderRows)
                                }
                            </Table.Body>
                        </Table>
                    </Grid.Row>
                </Grid>

            </div>
        </div>
    )
}

export default History;