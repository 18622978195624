import { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Form, Table } from "semantic-ui-react";
import { useForm, useFieldArray } from "react-hook-form";
import { FormDropdown, FormInput } from "components/FormInputs";
import TableComponent from "components/TableComponent";
import { Column } from "interface";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { requestChargeCodeStatusDropdown } from "store/rext";
import { useSelector } from "react-redux";
import { getChargeCodeSaveState, getChargeCodeStatusProjectDropdown } from "store/selectors";
import { transformDropdownOptions } from "containers/AddProject/utils";
import { chargeCodeStatusSave } from "store/list";
import { toast } from 'react-toastify';

const columns: Column[] = [
    { name: 'Sponsering PA', value: 'practiceArea', sort: false },
    { name: 'Co-sponsored Project', value: 'primary', sort: false },
    { name: 'Co-sponsored PA', value: 'practiceArea2', sort: false },
    { name: 'PA Charge Code', value: 'chargeCode', sort: false },
    { name: 'Co-sponsored approval status', value: 'statusId', sort: false }
];

interface PropTypes {
    data: any
}

const UpdateProjectData = ({ data }: PropTypes) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { data: dropDownData, fetching: dropdownFetching } = useSelector(getChargeCodeStatusProjectDropdown);

    const { fetching } = useSelector(getChargeCodeSaveState);

    const { register, control, handleSubmit, reset, setValue, getValues, trigger, watch } = useForm();

    const { fields } = useFieldArray({
        name: "sponserBOs",
        control
    });

    const formValues = watch();

    const renderRows = (item: any, index: number) => {
        const { practiceArea, statusId } = item;
        return (
            <Table.Row key={index}>
                <Table.Cell>
                {index === 0 && (
                    <p>{data?.primaryPaName}</p>
                )}
                </Table.Cell>
                <Table.Cell>
                    {index === 0 && (
                        <FormInput
                        customClass="hide-form-border"
                        name={`sponserBOs.${index}.primary`}
                        register={register}
                        readOnly
                    /> 
                    )}  
                </Table.Cell>
                <Table.Cell>{practiceArea}</Table.Cell>
                <Table.Cell>
                    <FormInput
                        name={`sponserBOs.${index}.chargeCode`}
                        register={register}
                    />
                </Table.Cell>
                <Table.Cell>
                    <FormDropdown
                        name={`sponserBOs.${index}.statusId`}
                        options={allOptions.paStatusOptions}
                        register={register}
                        getValues={getValues}
                        defaultValue={statusId.toString()}
                        setValue={setValue}
                        trigger={trigger}
                        customClass={index === 0 && "disable-edit grey-color"}
                    />
                </Table.Cell>
            </Table.Row>
        )
    }

    const allOptions = useMemo(() => {
        return {
            paStatusOptions: transformDropdownOptions(dropDownData?.dropdowns?.paStatus),
            projectStatusV1Options: transformDropdownOptions(dropDownData?.dropdowns?.projectStatusV1),
            projectStatusV2Options: transformDropdownOptions(dropDownData?.dropdowns?.projectStatusV2)
        }
    }, [dropDownData]);

    useEffect(() => {
        reset({
            projectStatusV1: data?.projectStatusV1Id ? data?.projectStatusV1Id.toString() : null,
            projectStatusV2: data?.projectStatusV2Id ? data?.projectStatusV2Id.toString() : null,
            sponserBOs: data.sponserBOs
        })
        setValue(`sponserBOs.${0}.primary`, data?.coSponsored);
    }, [data])

    useEffect(() => {
        dispatch(requestChargeCodeStatusDropdown());
    }, [dispatch])

    const onSubmit = handleSubmit(formData => {
        const payload = {...formData}
        payload.projectDetailsId = data.projectDetailsId;
        payload.projectName = data.projectName;
        payload.projectNumber = data.projectNumber;
        payload.projectStatusV1 = getTextById(formData.projectStatusV1, allOptions.projectStatusV1Options)
        payload.projectStatusV2 = getTextById(formData.projectStatusV2, allOptions.projectStatusV1Options);
        payload.projectStatusV1Id = Number(formData.projectStatusV1);
        payload.projectStatusV2Id = Number(formData.projectStatusV2);
        payload.sponserBOs[0].primary = true;
        
        dispatch(chargeCodeStatusSave(payload))
        setTimeout(() => {
            if(!fetching) {
                toast.info('Project Data Updated Successfully');
            }
        }, 1500);
    });

    const getTextById = (id: number, dropdownArr: any) => {
        const statusObject = dropdownArr.find((item: any) => item.value == id);
      
        return statusObject ? statusObject.text : "";
      }

    const onClickViewEdit = (type: string) => {
        if(type === 'view') {
            navigate(`/view-project?PA_ID=${data.primaryPaId}&projectID=${data.projectDetailsId}`)
        } else {
            navigate(`/edit-project?PA_ID=${data.primaryPaId}&projectID=${data.projectDetailsId}`) 
        }
    }

    return (
        <Fragment>
            <div className="flex flex-row margin-t-14">
                <div className="margin-r-2 flex-align-self-center">
                    <b>Project:</b> {data?.projectDetailsId} - {data?.projectName}({data?.projectNumber})
                </div>

                <div className="margin-l-6 flex-align-self-center">
                    <a onClick={() => onClickViewEdit('view')} className="text-underline cursor-pointer text-hover-underline">View</a>
                    <span className="padding-l-2 padding-r-2">|</span>
                    <a onClick={() => onClickViewEdit('edit')} className="text-underline cursor-pointer text-hover-underline">Edit</a>
                </div>
            </div>

            <Form size="small" onSubmit={onSubmit}>
                <div className="flex flex-row margin-t-6">
                    <b className="flex-align-self-center margin-r-4">Current Project Status</b>

                    <div className="width-40">
                        {!dropdownFetching && (
                            <FormDropdown
                                name='projectStatusV1'
                                options={allOptions.projectStatusV1Options || []}
                                register={register}
                                getValues={getValues}
                                setValue={setValue}
                                trigger={trigger}
                                customClass="disable-edit grey-color"
                            />
                        )}
                    </div>

                </div>

                <div className="flex flex-row margin-t-6">
                    <b className="flex-align-self-center  margin-r-5">New Project Status</b>

                    <div className="width-40 margin-l-5">
                    {!dropdownFetching && (
                        <FormDropdown
                            name='projectStatusV2'
                            options={allOptions.projectStatusV1Options || []}
                            register={register}
                            getValues={getValues}
                            setValue={setValue}
                            trigger={trigger}
                        />
                    )}
                    </div>
                </div>

                <div className="margin-t-8">
                    <TableComponent noDataMessage="There are no items." sortable={false} celled={true}
                        fetching={false} columns={columns} rows={fields}>
                        {
                            fields?.map(renderRows)
                        }
                    </TableComponent>
                </div>

                <div className="margin-t-8 flex flex-justify-end">
                    <Button type='submit' loading={fetching} size='tiny' primary>Update Project Data</Button>
                </div>
            </Form>
        </Fragment>
    )
}

export default UpdateProjectData;