import URLRoutes from "urlRoutes"
import { appRext, initiativeDropdownRext, addProjectDropdownRext, homeDropdownRext, projectHistoryDetails, adminChargeCodeRext, editProjectDetailsRext, requestSaveChangesRext, requestSubmitToFinanceRext, requestUpdateFinanceRext, projectStatusDropdownRext } from "./rext"

export function requestInitiativeDropdown() {
    return initiativeDropdownRext.request({
      method: "get",
      url: URLRoutes.server.initiativesDropdown,
    })
  }

  export function requestAddProjectDropdown(PA_ID: number) {
    return addProjectDropdownRext.request({
      method: "get",
      url: URLRoutes.server.addProjectDropdown,
      urlParams: {
        PA_ID
      }
    })
  }

  export function requestHomeDropdown() {
    return homeDropdownRext.request({
      method: "get",
      url: URLRoutes.server.homeDropdown
    })
  }

  export function requestEditProject(ProjectNumber: string) {
    return editProjectDetailsRext.request({
      method: "get",
      url: URLRoutes.server.editProject,
      urlParams: {
        ProjectNumber
      }
    })
  } 

  export function requestProjectHistory(projectId: string){
    return projectHistoryDetails.request({
      url:URLRoutes.server.projectHistory,
      method:"get",
      urlParams: {
        projectId,
      }
    })
  }

  export function requestAdminChargeCode(chargeCode: string){
    return adminChargeCodeRext.request({
      url:URLRoutes.server.adminChargeCode,
      method:"get",
      urlParams: {
        chargeCode
      },
    })
  }

  export function requestSaveChanges(body: any){
    return requestSaveChangesRext.request({
      url:URLRoutes.server.saveChanges,
      method:"put",
      body
    })
  }

  export function requestSubmitToFinance(body: any){
    return requestSubmitToFinanceRext.request({
      url:URLRoutes.server.projectSubmitToFinance,
      method:"post",
      body
    })
  }

  export function requestUpdateFinance(path: string, body: any){
    return requestUpdateFinanceRext.request({
      url:URLRoutes.server.projectFinanceStatus,
      method: "put",
      urlParams: {
        path
      },
      body
    })
  }

  export function requestChargeCodeStatusDropdown() {
    return projectStatusDropdownRext.request({
      method: "get",
      url: URLRoutes.server.projectStatusDropdown
    })
  }
  
  


