import UsePeople from 'HOC/UsePeople'
import TableComponent from 'components/TableComponent/TableComponent';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { getPeopleState } from 'store/selectors';

interface PropTypes {
    searchTerm: string;
    columns: any;
    onStaffSelect: (staff: any) => void;
}

const SingleUser = ({ searchTerm, columns, onStaffSelect }: PropTypes) => {
    const { user } = UsePeople(searchTerm)

    const peopleUserState = useSelector(getPeopleState(searchTerm));

    const renderRows = (item: any, index: number) => {
        return (
            <Table.Row key={index}>
                <Table.Cell><a className="cursor-pointer text-underline text-hover-underline" onClick={() => onStaffSelect(item)}>{item?.preferredName}</a></Table.Cell>
                <Table.Cell>{item?.hostOfficeRegion}</Table.Cell>
                <Table.Cell>{item?.businessTitle}</Table.Cell>
            </Table.Row>
        )
    }

    return (
        <>{peopleUserState !== undefined && (
            <TableComponent noDataMessage="There are no items." sortable={false} celled={false}
                fetching={peopleUserState?.fetching || false} columns={columns} rows={(peopleUserState?.user !== undefined || peopleUserState?.user !== null) ? Array(peopleUserState?.user) : []}>
                {
                    peopleUserState?.user !== undefined && Array(peopleUserState?.user).map(renderRows)
                }
            </TableComponent>
        )}
        </>
    )
}

export default SingleUser