import Breadcrumb from "components/Breadcrumb";
import TableComponent from "components/TableComponent";
import { Column } from "interface";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Table } from "semantic-ui-react";
import { requestInternsList } from "store/list";
import { getInternsList } from "store/selectors";

const columns: Column[] = [
    { name: 'Admin Id', value: 'admin_id', sort: false },
    { name: 'User Name', value: 'user_name', sort: false },
    { name: 'Email', value: 'email', sort: false },
    { name: 'Date Added', value: 'date_added', sort: false }
];

const ListInterns = () => {
    const dispatch = useDispatch();
    const { data: internsData, fetching: internsfetching } = useSelector(getInternsList)

    useEffect(() => {
        dispatch(requestInternsList())
    }, [dispatch])

    const renderRows = (item: any) => {
        const { hrId, memberName, email } = item;
        return (
            <Table.Row key={hrId}>
                <Table.Cell>{hrId}</Table.Cell>
                <Table.Cell>{memberName}</Table.Cell>
                <Table.Cell>{email}</Table.Cell>
                <Table.Cell>{item?.dateAdded}</Table.Cell>
            </Table.Row>
        )
    }

    return (
        <div className="padding-l-14 padding-r-14 margin-t-8">
            <Breadcrumb />

            <div className="width-50">
                <div className="margin-t-6">
                    <TableComponent noDataMessage="There are no items." sortable={false} celled={true}
                        fetching={!!internsfetching} columns={columns} rows={internsData || []}>
                        {
                            internsData?.map(renderRows)
                        }
                    </TableComponent>
                </div>
            </div>
        </div>
    )
}

export default ListInterns;