import Breadcrumb from "components/Breadcrumb";
import TableComponent from "components/TableComponent";
import { Column } from "interface";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { listAdmins } from "store/list";
import { getAdminList } from "store/selectors";
import { NAVIGATOR_URL } from "utils/global";

const columns: Column[] = [
    { name: 'HR Id', value: 'hr_id', sort: false },
    { name: 'Member Name', value: 'member_name', sort: false },
    { name: 'Alumni?', value: 'alumni', sort: false },
    { name: 'Office', value: 'office', sort: false },
    { name: 'Position', value: 'position', sort: false }
];

const ListAdmins = () => {

    const { data: adminList, fetching: adminListFetching } = useSelector(getAdminList);
    const dispatch = useDispatch();

    const renderRows = (item: any) => {
        return (
            <Table.Row key={item?.hrId}>
                <Table.Cell>{item?.hrId}</Table.Cell>
                <Table.Cell><a target="_blank" href={`${NAVIGATOR_URL}?hrid=${item?.hrId}`} className="text-underline text-hover-underline">{item?.memberName}</a></Table.Cell>
                <Table.Cell>{item?.isAlumni ? 'Alumni' : ''}</Table.Cell>
                <Table.Cell>{item?.office}</Table.Cell>
                <Table.Cell>{item?.position}</Table.Cell>
            </Table.Row>
        )
    }

    useEffect(() => {
        dispatch(listAdmins());
    }, [])

    return (
        <div className="padding-l-14 padding-r-14 margin-t-8">
            <Breadcrumb />

            <div className="margin-t-6 width-60">
                <TableComponent noDataMessage="There are no items." sortable={false} celled={true}
                    fetching={!!adminListFetching} columns={columns} rows={adminList}>
                    {
                        adminList?.map(renderRows)
                    }
                </TableComponent>
            </div>
        </div>
    )
}

export default ListAdmins;