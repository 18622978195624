import { FormDropdown, FormInput, FormTextArea } from "components/FormInputs";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { Button, Checkbox, Grid, Popup, Table } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { transformDropdownOptions } from "../utils";

const StaffingNeeds = ({ register, errors, getValues, setValue, trigger, reset, control, dropDownData, editData, isEditable, fetching, isViewMode }: any) => {

    const { fields, append } = useFieldArray({
        name: "staff",
        control
    });

    const dispatch = useDispatch();

    const watchCheckBox = useWatch({
        control,
        name: 'staff',
    });

    const watchProjectStaffed = useWatch({
        control,
        name: 'projectStaffed',
    });

    const allOptions = useMemo(() => {
        return {
            cohortDropdownOptions: transformDropdownOptions(dropDownData?.cohortDropdown),
            staffingUrgencyOptions: transformDropdownOptions(dropDownData?.staffingUrgencyDropdown),
            staffingPriorityPAOptions: transformDropdownOptions(dropDownData?.staffingPriorityPADropdown),
            regionPrefOptions: transformDropdownOptions(dropDownData?.regionPrefDropdown),
            colocationOptions: transformDropdownOptions(dropDownData?.staffingPurposeColocate),
        }
    }, [dropDownData]);

    const addStaff = () => {
        append({ cohort: '', regionPref1: 3, regionPref2: 3, pref1WithinRegion1: 50, pref2WithinRegion1: 10, pref1WithinRegion2: 'No', pref2WithinRegion2: 'Yes', capacity: 0, projectLength: 0, projectStaff: false },)
    }

    const staffRows = (item: any, index: number) => {
        return (
            <Table.Row key={index}>
                <Table.Cell>
                    <FormDropdown
                        name={`staff.${index}.cohort`}
                        options={allOptions.cohortDropdownOptions || []}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        disabled={!watchProjectStaffed ? false : true}
                        defaultValue={getValues(`staff.${index}.cohort`)}
                    />
                </Table.Cell>
                <Table.Cell width={2}>
                    <FormDropdown
                        name={`staff.${index}.regionPref1`}
                        options={allOptions.regionPrefOptions || []}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        disabled={!watchProjectStaffed ? false : true}
                        defaultValue={getValues(`staff.${index}.regionPref1`)}
                    />
                </Table.Cell>
                {/* <Table.Cell>
                    <FormInput
                        name={`staff.${index}.pref1WithinRegion1`}
                        register={register}
                        disabled={!watchProjectStaffed ? false : true}
                    />
                </Table.Cell>
                <Table.Cell>
                    <FormInput
                        name={`staff.${index}.pref2WithinRegion1`}
                        register={register}
                        disabled={!watchProjectStaffed ? false : true}
                    />
                </Table.Cell> */}
                <Table.Cell width={2}>
                    <FormDropdown
                        name={`staff.${index}.regionPref2`}
                        options={allOptions.regionPrefOptions || []}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        disabled={!watchProjectStaffed ? false : true}
                        defaultValue={getValues(`staff.${index}.regionPref2`)}
                    />
                </Table.Cell>
                {/* <Table.Cell>
                    <FormInput
                        name={`staff.${index}.pref1WithinRegion2`}
                        register={register}
                        disabled={!watchProjectStaffed ? false : true}
                    />
                </Table.Cell>
                <Table.Cell>
                    <FormInput
                        name={`staff.${index}.pref2WithinRegion2`}
                        register={register}
                        disabled={!watchProjectStaffed ? false : true}
                    />
                </Table.Cell> */}
                <Table.Cell>
                    <FormInput
                        name={`staff.${index}.capacity`}
                        register={register}
                        disabled={!watchProjectStaffed ? false : true}
                    />
                </Table.Cell>
                <Table.Cell>
                    <FormInput
                        name={`staff.${index}.projectLength`}
                        register={register}
                        disabled={!watchProjectStaffed ? false : true}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Checkbox
                        name={`staff.${index}.projectStaff`}
                        checked={watchCheckBox[index]?.projectStaff}
                        onClick={(e, { name, checked }) => {
                            setValue(name, checked);
                        }}
                        disabled={!watchProjectStaffed ? false : true}
                    />
                </Table.Cell>
            </Table.Row>
        )
    }

    const coLocationToolTipContent = () => {
        return (
            <>
                <ul>
                    <li>Primarily at client site: e.g., At client site on average 3 or more days per week, most weeks</li>
                    <li>Alternating colocation at client site & BCG office: e.g., First phase of a case with some weeks at client site followed by 1+ weeks at BCG office OR long-term client relationship which requires presence at the client only some weeks</li>
                    <li>BCG office based: e.g., Primarily at BCG office(s), at client when needed</li>
                    <li>Remote – by exception: e.g., Collaborate from a BCG home office</li>
                </ul>
            </>
        )
    }

    const openModal = () => {
        dispatch(modalOperation.showModal(ModalsType.AlertModal, {
            title: 'Alert',
            message: 'By checking this box, you confirm that this ALL staffing needs have already been filled, and the Staffing group will not seek additional resources.',
            onSave: () => {
                dispatch(modalOperation.hideModal());
            }
        }))
    }

    useEffect(() => {
        if (watchProjectStaffed) {
            setValue('staff', []);
        }
    }, [watchProjectStaffed])

    return (
        <div className="border-3 border-primary-color">
            <h5 className="text-background-primary text-white-color padding-1 margin-0">Staffing Needs</h5>
            <div className="padding-4 margin-b-4">

                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column className="checkbox-with-label">
                            <label htmlFor="checkboxId">Project is completely staffed</label>
                            <Checkbox
                                id="checkboxId"
                                name="projectStaffed"
                                onChange={(e, { name, checked }) => {
                                    setValue(name, checked);
                                    if (checked) {
                                        openModal();
                                    }
                                }}
                                checked={watchProjectStaffed}
                                readOnly={isViewMode}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <FormDropdown
                                    label='Staffing Urgency'
                                    name='staffingUrgency'
                                    options={allOptions.staffingUrgencyOptions || []}
                                    errors={errors}
                                    register={register}
                                    validation={{ required: !watchProjectStaffed ? true : false }}
                                    getValues={getValues}
                                    setValue={setValue}
                                    trigger={trigger}
                                    disabled={!watchProjectStaffed ? false : true}
                                />
                            </Grid.Column>

                            <Grid.Column>
                                <Grid>
                                    <Grid.Column width={14}>
                                        <FormDropdown
                                            label='	
                                    Staffing Priority within PA'
                                            name='stuffingPriorityPa'
                                            options={allOptions.staffingPriorityPAOptions || []}
                                            errors={errors}
                                            register={register}
                                            validation={{ required: !watchProjectStaffed ? true : false }}
                                            getValues={getValues}
                                            setValue={setValue}
                                            trigger={trigger}
                                            disabled={!watchProjectStaffed ? false : true}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={2} className="flex-align-self-center">
                                        <Popup size='mini' content='Please prioritize the urgency of this staffing request among other projects seeking staffing within your PA (1=highest priority). If this is the only project currently seeking staffing within your PA, please indicate top priority with a "1"' trigger={<img className="margin-t-7 cursor-pointer" src="../info.jpg" />} />
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Table size="small" className="margin-4">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell >Cohort</Table.HeaderCell>
                                <Table.HeaderCell>Region Pref. #1</Table.HeaderCell>
                                {/* <Table.HeaderCell>Pref 1 wthn Region #1</Table.HeaderCell>
                                <Table.HeaderCell>Pref 2 wthn Region #1</Table.HeaderCell> */}
                                <Table.HeaderCell>Region Pref. #2</Table.HeaderCell>
                                {/* <Table.HeaderCell>Pref 1 wthn Region #2</Table.HeaderCell>
                                <Table.HeaderCell>Pref 2 wthn Region #2</Table.HeaderCell> */}
                                <Table.HeaderCell>Cap.(% FTE)</Table.HeaderCell>
                                <Table.HeaderCell>Project Length(weeks)</Table.HeaderCell>
                                <Table.HeaderCell>
                                    <span className="float-left">Project Staffed</span>
                                    <Popup size='mini' content='By checking this box, you confirm that this identified staffing need has already been filled, and the Staffing group will not seek additional resources.' trigger={<img className="margin-l-2 cursor-pointer" src="../info.jpg" />} />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                fields?.map(staffRows)
                            }
                            {!isViewMode && (
                                <Table.Row>
                                    <Table.Cell width={3}>
                                        <Button type="button" size="mini" disabled={!watchProjectStaffed ? false : true} onClick={addStaff}>Add an Additional Staff Request</Button>
                                    </Table.Cell>
                                    <Table.Cell colSpan={12}></Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>

                    <Grid>
                        <Grid.Row columns={1}>
                            <Grid.Column width={10}>
                                <FormTextArea
                                    label='Public requirements (what types of profiles are required, e.g., skills or expertise, previous client experience, etc.)'
                                    name='publicRequirements'
                                    register={register}
                                    rows={3}
                                    disabled={!watchProjectStaffed ? false : true}
                                    readOnly={isViewMode}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <Grid.Column width={10}>
                                <FormDropdown
                                    label='Purposeful Colocation Model'
                                    name='purposefulColocation'
                                    options={allOptions.colocationOptions || []}
                                    // errors={errors}
                                    register={register}
                                    // validation={{ required: !watchProjectStaffed ? true : false }}
                                    getValues={getValues}
                                    setValue={setValue}
                                    trigger={trigger}
                                    disabled={!watchProjectStaffed ? false : true}
                                />
                            </Grid.Column>
                            <Grid.Column width={2} className="flex-align-self-center">
                                <Popup size='mini' content={coLocationToolTipContent} trigger={<img className="margin-t-7 cursor-pointer" src="../info.jpg" />} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </>
            </div>
        </div>
    )
}

export default StaffingNeeds;