import { Fragment } from "react"
import { Loader, Table } from 'semantic-ui-react'

const TableLoading = ({ columns }: any) => {

    return (
        <Fragment>

            <Table size='small' celled>
                <Table.Header>
                    <Table.Row>
                        {
                            columns.map((column: any) => (
                                <Table.HeaderCell key={column?.value} width={column?.width}>{column?.name}</Table.HeaderCell>
                            ))
                        }
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    <Table.Row>
                        <Table.Cell colSpan={columns.length} textAlign='center'>
                            <div className="padding-5">
                                <Loader active inline='centered' size='small'>Loading</Loader>
                            </div>
                        </Table.Cell>
                    </Table.Row>

                </Table.Body>
            </Table>


        </Fragment >
    );
}

export default TableLoading;