import Breadcrumb from "components/Breadcrumb";
import TableComponent from "components/TableComponent";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { Column } from "interface";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { modalOperation } from "store/actions";
import { requestPAList, updatePAList } from "store/list";
import { getAdminPAList } from "store/selectors";

const columns: Column[] = [
    { name: 'PA ID', value: 'paId', sort: false },
    { name: 'PA Name', value: 'paId', sort: false },
    { name: 'AD Group Name', value: 'adGroupName', sort: false },
    { name: '# Projects', value: 'numberOfProjects', sort: false },
    { name: '# Users', value: 'numberOfUsers', sort: false },
    { name: 'Block Emails', value: 'blockEmails', sort: false },
    { name: 'Approver HR ID', value: 'approver_staff_id', sort: false },
    { name: 'Approver Name', value: 'approver_name', sort: false },
    { name: '', value: 'change_approver_nav', sort: false },
    // { name: '', value: 'change_approver_idb', sort: false },
    { name: '', value: 'change_approver_id', sort: false }
];

const PAList = () => {
    const dispatch = useDispatch();

    const { data: PaList, fetching: paListfetching } = useSelector(getAdminPAList);

    const renderRows = (item: any) => {
        const { paId, adGroupName, approverHrId, approverStaffId, blockEmails, numberOfProjects, numberOfUsers, paName, approverFirstName, approverLastName } = item;
        return (
            <Table.Row key={paName}>
                <Table.Cell><Link to={`/projects?PA_ID=${paId}`} className="text-underline text-hover-underline">{paId}</Link></Table.Cell>
                <Table.Cell>{paName}</Table.Cell>
                <Table.Cell><Link to={`/admin-options/pa-list/pa-details?PA_ID=${paId}&PA_NAME=${paName}&AD_GROUP=${adGroupName}`} className="text-underline text-hover-underline">{adGroupName}</Link></Table.Cell>
                <Table.Cell>{numberOfProjects}</Table.Cell>
                <Table.Cell>{numberOfUsers}</Table.Cell>
                <Table.Cell>{blockEmails}</Table.Cell>
                <Table.Cell>{approverHrId}</Table.Cell>
                <Table.Cell><a href={`https://profiles.navigator.bcg.com/overview?hrid=${approverHrId}`} target="_blank" className="cursor-pointer">{`${approverFirstName} ${approverLastName}`}</a></Table.Cell>
                <Table.Cell><a onClick={() => handleStaffChooser('nav', paId)} className="text-underline text-hover-underline cursor-pointer">Change approver</a></Table.Cell>
                {/* <Table.Cell><a onClick={() => handleStaffChooser('nav', null)} className="text-underline text-hover-underline">Change approver (IDB)</a></Table.Cell> */}
                <Table.Cell><a onClick={() => handleStaffChooser('hrid', null)} className="text-underline text-hover-underline cursor-pointer">Change approver (by ID)</a></Table.Cell>
            </Table.Row>
        )
    }

    const handleStaffChooser: any = (section: string, id: number) => {
        openStaffChooser(section, id);
    };

    const openStaffChooser = (type: string, paId: any) => {
        dispatch(modalOperation.showModal(ModalsType.StaffChooserModal, {
            onSelect: (data: any) => {
                let payload = { hrId: data.id, name: data.preferredName }
                dispatch(updatePAList(paId, payload));
                dispatch(modalOperation.hideModal())
                setTimeout(() => {
                    dispatch(requestPAList());
                }, 2000);
            },
            type
        }))
    }

    useEffect(() => {
        dispatch(requestPAList());
    }, [dispatch]);

    return (
        <div className="padding-l-14 padding-r-14 margin-t-8">
            <Breadcrumb />

            <div className="margin-t-6">
                <TableComponent noDataMessage="There are no items." sortable={false} celled={true}
                    fetching={paListfetching || false} columns={columns} rows={PaList}>
                    {
                        PaList?.map(renderRows)
                    }
                </TableComponent>
            </div>
        </div>
    )
}

export default PAList;