import { FormInput, FormTextArea } from "components/FormInputs";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Button, Grid, Popup, Table, Input, Label } from "semantic-ui-react";
import { modalOperation } from "store/actions";
import { formatUSD, transformDropdownOptions } from "../utils";
import { PEOPLE } from "interface";



const Leadership = ({ register, errors, getValues, setValue, trigger, reset, control, setError, paId, dropDownData, editData, isEditable, isViewMode, emailCodeData }: any) => {
    const dispatch = useDispatch();
    const [leadPartnerDetails, setLeadPartnerDetails] = useState<PEOPLE>();
    const [projectManagerDetails, setProjectManagerDetails] = useState<PEOPLE>();
    const [expenseAmount, setExpenseAmount] = useState<string>('');
    const [budgetTimeAmount, setBudgetTimeAmount] = useState<string>('');
    const [sumTotal, setSumTotal] = useState<any>('');

    const { fields: cpLeadFields, append: cpLeadAppend } = useFieldArray({
        name: "coLead",
        control,
        rules: ({ required: false })
    });

    const { fields: steeringCommitteFields, append: steeringCommitteAppend } = useFieldArray({
        name: "steeringCommitte",
        control
    });

    const watchFunding = useWatch({
        control,
        name: 'funding',
    });

    const addPartner = () => {
        cpLeadAppend({ partner: '' });
    }

    const addCommitte = () => {
        steeringCommitteAppend({ steeringCommitte: '' });
    }

    const coLeadRows = (item: any, index: number) => {
        return (
            <div className="flex" key={index}>
                <FormInput
                    label={index == 0 ? `Co-Lead MDP` : ''}
                    name={`coLead.${index}.partner`}
                    value={getCoLeadName(index)}
                    register={register}
                    readOnly={isViewMode}
                    customClass="disable-edit"
                />
                {!isViewMode && (
                    <img className="cursor-pointer flex-align-self-center margin-l-2 margin-t-2" src="../lookup.jpg" onClick={() => handleStaffChooser('co-lead', index)} />
                )}
            </div>
        );
    }

    const steeringCommitteRows = (item: any, index: number) => {
        return (
            // className={`flex ${item.steeringCommitte == '' ? 'error' : ''}`}
            <div className={`flex`} key={index}>
                {index == 0 && (
                    <>
                        <FormInput
                            label={`Proposed Steering Committee`}
                            name={`steeringCommitte.${index}.steeringCommitte`}
                            value={getSteeringCommitteName(index)}
                            errors={errors}
                            register={register}
                            validation={{ required: true }}
                            rules={{ required: 'This field is required' }}
                            trigger={trigger}
                            customClass="disable-edit"
                        />
                        {!isViewMode && (
                            <img className="cursor-pointer flex-align-self-center margin-l-2 margin-t-2" src="../lookup.jpg" onClick={() => handleStaffChooser('steering', index)} />
                        )}
                    </>
                )}
                {index !== 0 && (
                    <div className="hide-label flex">
                        <FormInput
                            name={`steeringCommitte.${index}.steeringCommitte`}
                            value={getSteeringCommitteName(index)}
                            errors={errors}
                            register={register}
                            validation={{ required: true }}
                            trigger={trigger}
                            readOnly={true}
                        />
                        {!isViewMode && (
                            <img className="cursor-pointer flex-align-self-center margin-l-2 margin-t-2" src="../lookup.jpg" onClick={() => handleStaffChooser('steering', index)} />
                        )}
                    </div>
                )}
            </div>
        );
    }

    const renderSponserFinancialRows = (item: any, index: number) => {
        const { paName, timeFunded, expenseFunded } = item;
        return (
            <Table.Row key={index}>
                <Table.Cell>
                    <span>{getPaName(paName)[0]?.text}</span>
                </Table.Cell>
                <Table.Cell>
                    <span>${formatUSD(getTimeFundedValue(budgetTimeAmount, timeFunded).toFixed(2))}</span>
                </Table.Cell>
                <Table.Cell>
                    <span>${formatUSD(getTimeFundedValue(expenseAmount, expenseFunded).toFixed(2))}</span>
                </Table.Cell>
                <Table.Cell>
                    <span>${formatUSD((getTimeFundedValue(budgetTimeAmount, timeFunded) + getTimeFundedValue(expenseAmount, expenseFunded)).toFixed(2))}</span>
                </Table.Cell>
            </Table.Row>
        )
    }

    const watchBudget = useWatch({
        control,
        name: ['budgetTime', 'budgetExpense'],
    });

    const watchLeadPartner = useWatch({
        control,
        name: 'leadPartner',
    });

    const openStaffChooser = (section: string, id = 0) => {
        dispatch(modalOperation.showModal(ModalsType.StaffChooserModal, {
            onSelect: (data: any) => {
                let mappedData = null;
                if (data == null) {
                    mappedData = '';
                } else {
                    mappedData = {
                        partnerId: data.id,
                        email: data.email,
                        preferredName: data.preferredName
                    }
                }
                if (section === 'co-lead') {
                    setValue(`coLead.${id}.partner`, mappedData);
                } else if (section === 'steering') {
                    let mapData = {
                        hrId: data.id,
                        email: data.email,
                        preferredName: data.preferredName
                    }
                    setValue(`steeringCommitte.${id}.steeringCommitte`, mapData);
                } else if (section === 'pm') {
                    setProjectManagerDetails(data);
                    setValue(`projectManager`, data);
                } else {
                    setLeadPartnerDetails(data);
                    setValue(`leadPartner`, mappedData);
                }
                dispatch(modalOperation.hideModal())
            }
        }))
    }

    const getCoLeadName = (index: number) => {
        let name = getValues(`coLead.${index}.partner.preferredName`) || '';
        return name;
    };

    const getSteeringCommitteName = (index: number) => {
        let name = getValues(`steeringCommitte.${index}.steeringCommitte.preferredName`) || '';
        return name;
    };

    const handleStaffChooser: any = (section: string, id: number) => {
        openStaffChooser(section, id);
    };

    const allOptions = useMemo(() => {
        return {
            coSponsoredPAOptions: transformDropdownOptions(dropDownData?.coSponsoredPADropdown)
        }
    }, [dropDownData]);

    const getPaName = (paName: number) => {
        return allOptions?.coSponsoredPAOptions.filter((val: any) => val.value == paName);
    }
    const getTimeFundedValue = (amount: string, value: any) => {
        const formattedValue: any = amount?.toString().replace(/,/g, '');
        if (formattedValue) {
            return (formattedValue * value) / 100;
        } else {
            return 0
        }
    }

    useEffect(() => {
        const budgetTime = getValues('budgetTime');
        const budgetExpense = getValues('budgetExpense');
        let sum: any = '';
        if (budgetTime !== '' || budgetExpense !== '') {
            const timeReplaceComma = budgetTime?.toString().replace(/,/g, '');
            const expenseReplaceComma = budgetExpense?.toString().replace(/,/g, '');
            sum = Number(timeReplaceComma) + Number(expenseReplaceComma);
        }

        setExpenseAmount(budgetExpense);
        setBudgetTimeAmount(budgetTime);
        setSumTotal(sum);

        if(sum !== '') {
            setValue('budgetTotal', formatUSD(sum));
        }
    }, [watchBudget]);

    useEffect(() => {
        if ((isEditable || isViewMode) && (Object.keys(editData).length || Object.keys(emailCodeData).length)) {
            setLeadPartnerDetails(getValues('leadPartner'));
            setProjectManagerDetails(getValues('projectManager'));
        }
    }, [isEditable, watchLeadPartner]);

    useEffect(() => {
        reset({
            coLead: [
                { partner: '' }
            ],
            steeringCommitte: [
                { steeringCommitte: '' }
            ]
        })
    }, []);

    const handleAmountChange = (event: any, property: string):any => {
        event.stopPropagation();
        const rawValue = event.target.value;

        const replaceComma = rawValue?.replace(/,/g, '');
        const numberValue = parseFloat(replaceComma);

        const formattedValue = replaceComma ? formatUSD(numberValue) : '';

        if(property === 'budgetExpense') {
            setExpenseAmount(formattedValue);
        } else {
            setBudgetTimeAmount(formattedValue)
        }
        setValue(property, formattedValue);
    };

    return (
        <div className="border-3 border-primary-color">
            <h5 className="text-background-primary text-white-color padding-1 margin-0">Leadership + Financials</h5>
            <div className="padding-4">

                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <div className="flex">
                                <FormInput
                                    label='Lead MDP'
                                    name='leadPartner'
                                    register={register}
                                    errors={errors}
                                    validation={{ required: true }}
                                    value={leadPartnerDetails?.preferredName || ''}
                                    customClass="disable-edit"
                                />
                                {!isViewMode && (
                                    <img className="cursor-pointer flex-align-self-center margin-l-2 margin-t-2" src="../lookup.jpg" onClick={() => handleStaffChooser('lead-partner', null)} />
                                )}
                            </div>

                            {
                                cpLeadFields?.map(coLeadRows)
                            }
                            {!isViewMode && (
                                <Button type="button" size="mini" onClick={addPartner}>Add an additional MDP</Button>
                            )}

                            <div className="margin-t-4">
                                {
                                    steeringCommitteFields?.map(steeringCommitteRows)
                                }
                                {errors?.steeringCommitte?.length && (<p className="text-form-error">This field is required</p>)}
                                {!isViewMode && (
                                    <Button type="button" size="mini" onClick={addCommitte}>Add an additional Member</Button>
                                )}
                            </div>

                            <div className="flex margin-t-4">
                                <FormInput
                                    label='Project Manager'
                                    name='projectManager'
                                    register={register}
                                    value={projectManagerDetails?.preferredName || ''}
                                    customClass="disable-edit"
                                />
                                {!isViewMode && (
                                    <img className="cursor-pointer flex-align-self-center margin-l-2 margin-t-2" src="../lookup.jpg" onClick={() => handleStaffChooser('pm', null)} />
                                )}
                                <Popup size='mini' content="The lead contact for the project and it's content.  This contact may also be the Lead Partner, or may also be a P or PL." trigger={<img className="cursor-pointer flex-align-self-center margin-l-2 margin-t-2" src="../info.jpg" />} />
                            </div>

                            <FormTextArea
                                label='Additional Comments'
                                name='leadershipAdditionalComments'
                                register={register}
                                rows={3}
                                readOnly={isViewMode}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <div>
                                <label className="required">Proposed Project Budget</label>
                                <Table className={`${isViewMode ? 'view-mode-enabled' : ''}`} size="small">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell ></Table.HeaderCell>
                                            <Table.HeaderCell><label className="required">Budget</label></Table.HeaderCell>
                                            {/* <Table.HeaderCell><label>Actual</label></Table.HeaderCell> */}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>Time ($)</Table.Cell>
                                            <Table.Cell>
                                                <Input
                                                    labelPosition='right'
                                                    type='text'
                                                    placeholder='Amount'
                                                    name="budgetTime"
                                                >
                                                    <Label basic>$</Label>
                                                    <input className={`field-width ${errors?.budgetTime ? 'field-required' : ''}`} {...register('budgetTime', {
                                                        required: true
                                                    })}
                                                    onChange={(e) => handleAmountChange(e, 'budgetTime')}
                                                    />
                                                    <Label>.00</Label>
                                                </Input>
                                            </Table.Cell>
                                            {/* <Table.Cell>
                                                <FormInput
                                                    name='actualTime'
                                                    register={register}
                                                />
                                            </Table.Cell> */}
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell>Expense ($)</Table.Cell>
                                            <Table.Cell>
                                                <Input
                                                    labelPosition='right'
                                                    type='text'
                                                    placeholder='Amount'
                                                    name="budgetExpense"
                                                >
                                                    <Label basic>$</Label>
                                                    <input className={`field-width ${errors?.budgetExpense ? 'field-required' : ''}`} {...register('budgetExpense', {
                                                        required: true
                                                    })}
                                                    onChange={(e) => handleAmountChange(e, 'budgetExpense')}
                                                    />
                                                    <Label>.00</Label>
                                                </Input>
                                            </Table.Cell>
                                            {/* <Table.Cell>
                                                <FormInput
                                                    name='actualExpense'
                                                    register={register}
                                                />
                                            </Table.Cell> */}
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell width={4}>Total Funding ($)</Table.Cell>
                                            <Table.Cell>
                                                <Input
                                                    labelPosition='right'
                                                    type='text'
                                                    placeholder='Amount'
                                                    name="budgetTotal"
                                                >
                                                    <Label basic>$</Label>
                                                    <input className={`disable-edit field-width`} {...register('budgetTotal')} />
                                                    <Label>.00</Label>
                                                </Input>
                                            </Table.Cell>
                                            {/* <Table.Cell>
                                                <FormInput
                                                    name='actualTotal'
                                                    register={register}
                                                />
                                            </Table.Cell> */}
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>

                            <div className="text-background-secondary padding-2 margin-t-6">
                                <p className="margin-0">PA Sponsor Financials</p>
                            </div>

                            <Table size="small">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>PA</Table.HeaderCell>
                                        <Table.HeaderCell>Contribution Time ($)</Table.HeaderCell>
                                        <Table.HeaderCell><label>Contribution Expense ($)</label></Table.HeaderCell>
                                        <Table.HeaderCell><label>Contribution Total ($)</label></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {watchFunding?.map(renderSponserFinancialRows)}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </div>
        </div>
    )
}

export default Leadership;