import { SagaIterator } from 'redux-saga';
import { put, call, select, takeLatest } from 'redux-saga/effects';
import { rextActionFunctions } from './actions';
import { fetchPeopleRequest, fetchRequest, getFullUrl } from 'Api';
import { IRextAction, ActionIdentity } from './interface';

function* performRequestRextOperation(action: IRextAction): IterableIterator<{}> {

  const { meta, payload } = action;
  const { url, method } = payload.params!;
  const {
    keys: { getBaseUrl, getToken, getXAPIToken },
  } = meta;

  let fetchingMethod = null;
  if(meta.keys.identity === 'searchBCGUsers') {
    fetchingMethod = fetchPeopleRequest;
  } else {
    fetchingMethod = fetchRequest;
  }
  try {
    const token: string = (yield select(getToken))!;
    const apiKey: string =(yield select(getXAPIToken))!;

    const requesturl: string = getFullUrl((yield select(getBaseUrl))!, url, payload.params);
    const response: any = yield call(
      fetchingMethod,
      requesturl,
      token,
      method,
      apiKey,
      payload?.params?.body,
      payload?.params?.headers,
    );
    yield put(
      rextActionFunctions.success(
        meta,
        payload.params!,
        response.data || response.record || response,
        response.message || 'Execution Done Successfully',
      ),
    );
  } catch (error: any) {
    const message = error.message || error.errorText || error ;
    yield put(rextActionFunctions.failure(meta, payload.params!, message));
  }
}

export default function (actionidentity: ActionIdentity) {
  return function* watchRextEvent(): SagaIterator {
    yield takeLatest(actionidentity.REXT_FETCH.REQUEST, performRequestRextOperation);
    yield takeLatest(actionidentity.REXT_UPDATE.REQUEST, performRequestRextOperation);
  };
}
